import { get, put, post, delete as del, /*head, options, patch,*/ } from 'axios';
const adminApi = {
    getAdminUsers: () => get('/adminApi/AdminUsers'),
    getUsers: () => get( '/adminApi/Users' ),
    updateUser: ( user ) => {
        if ( user.userId === 0 ) {
            return post( "/adminApi/Users", user );
        }
        return put( "/adminApi/Users", user );
    },
    updateUserLite: (user) =>  put('/adminApi/Users/UpdateUserLite', user),
    
    getUser: (id) => get(`/adminApi/Users/${id}`),
    getRoles: () => get('/adminApi/Roles'),
    getDatabases: () => get('/adminApi/Databases'),
    getDatabase: (id) => get(`/adminApi/Databases/${id}`),
    updateDatabase: (database) => database.databaseId === 0 ? post("/adminApi/Databases", database) : put("/adminApi/Databases", database),
    deleteDatabase: (id) => del(`/adminApi/Databases/${id}`),
    canDeleteDatabase: (id) => get(`/adminApi/Databases/CanDelete/${id}`),
    getSystemSettings: () => get('/adminApi/SystemSettings'),
    updateSystemSettings: (systemSettings) => put('/adminApi/SystemSettings', systemSettings),
    updateEmailSettings: (emailSettings) => put('/adminApi/EmailSettings', emailSettings),
    updateResetPasswordSettings: (passwordSettings) => put('/adminApi/ResetPasswordSettings', passwordSettings),
    updateRecordCountSettings: (recordCount) => put('/adminApi/DataViewSettings', recordCount),
    updateBizweaverSettings: (settings) => put('/adminApi/BizweaverSettings', settings),
    updateSessionPeriodSettings: (settings) => put('/adminApi/SiteSettings', settings),
    updateTimeZoneSettings: (settings) => put('/adminApi/TimeZoneSettings', settings),
    updateFileUploadSettings: (settings) => put('/adminApi/FormSettings', settings),
    updateInstallationIdSettings: (settings) => put('/adminApi/InstallationSettings', settings),
    updatePaymentGatewaySettings: (settings) => put('/adminApi/PaymentSettings', settings),
    getSiteTheme: () => get('/adminApi/SiteTheme'),
    updateSiteTheme: (siteTheme) => put('/adminApi/SiteTheme', siteTheme),
    checkSmtpSettings: (emailSettings) => post('api/mail/adminApi/CheckSmtpSettings', emailSettings), 
    getFilters: () => get('adminApi/Filters'),
    getFilter: (id) => get(`adminApi/Filter/${id}`),
    userGetFilter: (id) => get(`adminApi/Filters/${id}`),//Calling to userController
    updateFilter: (filter) => filter.profileId === 0 ? post('adminApi/Filters', filter) : put("/adminApi/Filters", filter),
    deleteFilter: (fid) => del(`adminApi/Filters/${fid}`),
    canDeleteFilter: (fid) => get(`adminApi/CanDeleteFilter/${fid}`),
    getDatabaseList: () => get('adminApi/DatabaseList'),
    getDataSourceList: (companyId) => get(`adminApi/DatasourceList/${companyId}`),
    getFieldsList: (companyId, ds) => get(`adminApi/DatafieldsList/${companyId}/${ds}`) ,
    getActiveUser: (roleId) => get(`api/Login/CheckUser/${roleId}`),
    getReports: () => get('/adminApi/Reports'),
    getForms: () => get('/adminApi/GetForms'),
    canDeleteForm: (fid) => get(`adminApi/CanDeleteForm/${fid}`),
    getFormsControls: (formId) => get(`/api/FormDefinition/FormsControls/${formId}`),
    getDashboards: () => get('/adminApi/GetDashboards'),
    getDashboard: (id) => get(`adminApi/GetDashboard/${id}`),
    updateDashboard: (dashboard) => dashboard.id === 0 ? post('/adminApi/Dashboards', dashboard) : put("/adminApi/Dashboards", dashboard),
    deleteDashboard: (id) => del(`/adminApi/Dashboards/${id}`),
    canDeleteDashboard: (id) => get(`/adminApi/CanDeleteDashboard/${id}`),
    getDashboardItemsByRoleList: (roleList) => get(`/adminApi/DashboardItemListByRoleList/?${roleList}`),
    getMenus: () => get('/adminApi/Menus'),
    getMenu: (id) => get(`/adminApi/Menus/${id}`),
    getRolesForMenu: (id) => get(`/adminApi/GetRoleListForMenu/${id}`),
    deleteMenu: (id) => del(`/adminApi/Menus/${id}`),
    updateMenu: (menu) => menu.menuId === 0 ? post("/adminApi/Menus", menu) : put("/adminApi/Menus", menu),
    getAllMenus: () => get(`/adminApi/Menus/GetMenus`),
    getRole: (id) => get(`/adminApi/Roles/${id}`),
    getDataViews: () => get('/adminApi/DataViews'),
    getDataViewListByRoleList: (roleList) => get(`/adminApi/DataViews/DataViewListByRoleList/?${roleList}`),
    getDataViewListAndFormListByRoleList: (roleList) => get(`/adminApi/DataViews/DataViewListAndFormListByRoleList/?${roleList}`),
    deleteDataView: (id) => del(`/adminApi/DataViews/${id}`),
    canDeleteDataView: (id) => get(`/adminApi/DataViews/CanDelete/${id}`),
    getDataView: (id) => get(`/adminApi/DataViews/${id}`),
    getCategories: () => get('/adminApi/DataViews/Categories'),
    getDataViewSourceList: (companyId, type) => get(`adminApi/DataViews/DataSourceList/${companyId}/${type}`),
    getDataViewSourceFieldList: (companyId, type, dataSource) => get(`adminApi/DataViews/DataSourceFieldList/${companyId}/${type}/${dataSource}`),
    getDataViewQueryFieldList: (queryObject) => post(`adminApi/DataViews/QueryFieldList`, queryObject),
    updateRole: (role) => role.roleId === 0 ? post("/adminApi/Roles", role) : put("/adminApi/Roles", role),
    deleteRole: (id) => del(`/adminApi/Roles/${id}`),
    canDeleteRole: (id) => get(`/adminApi/CanDeleteRole/${id}`),
    getDataViewRecordFields: (id) => get(`/adminApi/DataViews/DataViewsFields/${id}`),
    updateDataView: (dataView) => dataView.reportId === 0 ? post("/adminApi/DataViews", dataView) : put("/adminApi/DataViews", dataView),
    getDataViewFieldsLink: (id) => get(`/adminApi/DataViews/DataViewsLinks/${id}`),
    getFieldDisplay: (id) => get(`/adminApi/FieldDisplay/${id}`),
    executeStoredProcedureWithParameters: (dataView) => post("/adminApi/DataViews/ExecuteStoredProcedure", dataView),
    getCalculatedFields: (dbType) => get(`/adminApi/FieldDisplay/${dbType}`),
    getValidateFormula: (dataView) => post('/adminApi/FieldDisplay', dataView),
    getDataViewDependencyList: (id) => get(`/adminApi/DataViews/GetDependencyObjectList/${id}`),
    getTableList: (companyId) => get(`adminApi/FormBuilder/TableList/${companyId}`),
    getTableFieldList: (companyId, tableName) => get(`adminApi/FormBuilder/TableFieldList/${companyId}/${tableName}`),
    getForm: (id) => get(`/adminApi/FormBuilder/${id}`),
    updateForm: (form) => form.formId === 0 ? post("/adminApi/FormBuilder", form) : put("/adminApi/FormBuilder", form),
    deleteForm: (id) => del(`adminApi/Forms/${id}`),
    getCountryList: () => get('adminApi/CountryList'),
    getHomePageList: () => get('/adminApi/GetHomePage'),
    updateHomePage: (homePageContent) => homePageContent.id === 0 ? post("/adminApi/HomePage", homePageContent) : put("/adminApi/HomePage", homePageContent),
    deleteHomePage: (homePageContent) => post('/adminApi/DeleteHomePage', homePageContent),
    getCustomWidgetList: () => get('adminApi/GetCustomWidgetList'),
    updateCustomWidget: (customWidget) => put('/adminApi/CustomWidget', customWidget),
    getHomePageContent: (id, type) => get(`/adminApi/GetHomePageContent/${id}/${type}`),
    publishHomePage: (homePageContent) => post("/adminApi/publishHomePage", homePageContent),
    generateExportFile: (exportPackage) => post("/adminApi/Package/GenerateExportFile", exportPackage, { responseType: 'blob'}),
    getPackageLogFile: (isImport) => isImport ? get("/adminApi/Package/GetImportLogFile"): get("/adminApi/Package/GetExportLogFile"),
    getExportList: () => get("/adminApi/Package/GetExportList"),
    GetDependencyList: (dependencyPackage) => post('/adminApi/Package/GetDependencyList', dependencyPackage),
    hasObjectDependency: (type, id, roleId) => get(`/adminApi/checkDependency/${type}/${id}/${roleId}`),
    importFile: (importPackage) => post("/adminApi/Package/ImportFile", importPackage),
    validatePackage: (validatePackage) => post("/adminApi/Package/ValidatePackage", validatePackage),
    getVersion: () => get('/adminApi/Version'),
   };

export default adminApi;
