const requestLoading = 'REQUEST_LOADING'
const initialState = { loading:false };

export const loadingAction = {


    setLoading: (status) => (dispatch, getState) => {
       
        dispatch({ type: requestLoading, status: status });
       
    },
}


export const reducer = (state, action) => {

    state = state || initialState;

    if (action.type === requestLoading ) {
        return {
            ...initialState,
            loading: action.status
        };
    }
    
    return state;
}