import React, { useRef} from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { makeStyles } from '@mui/styles';
import { spacing } from '@mui/system';
import TextField from '@mui/material/TextField';

import InputAdornment from '@mui/material/InputAdornment';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: spacing(1)
        }
    }
}));

const numberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;
    let decimalSettings = {};
    if (props.defaultValue.decimalScale) {
        decimalSettings.decimalScale = props.defaultValue.decimalScale
        decimalSettings.fixedDecimalScale =true
    }
    else if (props.defaultValue.decimalScale ===0) {
        decimalSettings.decimalScale = 0
    }
    
   
    let textSettings = {};
    if (props.defaultValue.textAlign) {
        textSettings.textAlign = props.defaultValue.textAlign
    }
    return (
        <NumberFormat
            {...other}
            style={{ ...textSettings }}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value
                    }
                });
            }}
            autoFocus={props.defaultValue.hasFocus}
            thousandSeparator={props.defaultValue.thousandSeparator}
            isNumericString={true}
            decimalSeparator={props.defaultValue.decimalSeparator}
            prefix={props.defaultValue.prefix}
            {...decimalSettings}
        />
    );
});

numberFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};
let blurValue = { value: false }, fromSpiner = false, resetToBlank = false;

export default function (props) {
    const incrementButtonRef = useRef(null);
    const classes = useStyles();
    let value = (isNaN(props.dataModel[props.column.fieldName]) || props.dataModel[props.column.fieldName] === null) ? "" : props.dataModel[props.column.fieldName];
    if (blurValue.value && blurValue.controlId !== props.column.controlId) {
        blurValue.value = false;
        blurValue.controlId = props.column.controlId;
    }
    const [values, setValues] = React.useState({
        numberFormat: value
    });
    const IncNum = () => {
        fromSpiner = true;
        incrementButtonRef.current.offsetParent.firstChild.focus();
        const currentValue = parseFloat(values.numberFormat)
        if (isNaN(currentValue)) {
            setValues({

                numberFormat:0,
            });
        }
        else {
            setValues({

                numberFormat: currentValue + 1,
            });
        }
        
        
    };

    const DecNum = () => {
        fromSpiner = true;
        incrementButtonRef.current.offsetParent.firstChild.focus();
        const currentValue = parseFloat(values.numberFormat)
        if (isNaN(currentValue)) {
            setValues({

                numberFormat: 0,
            });
        }
        else {
            setValues({

                numberFormat: currentValue - 1,
            });
        }
        
    };
   
    React.useEffect(() => {
        resetToBlank = false;
        if (value !== "" && parseFloat(value) !== parseFloat(values.numberFormat)) {
            if (!blurValue.value && !fromSpiner) {
                setValues({
                    ...values,
                    numberFormat: value
                });
            }
            else
                blurValue.value = false;
        }
        else if (value === "" && values.numberFormat !== "")
        {
            if (!blurValue.value ) {
                setValues({
                    ...values,
                    numberFormat: value
                });
                resetToBlank = true;
            }
        }
        

    }, [value, values])

    const handleChange = (event) => {
        if (!resetToBlank) {
            setValues({
                ...values,
                numberFormat: event.target.value
            });
            const floatValue = parseFloat(event.target.value);
            if (floatValue !== value) {
                blurValue.value = true;
                blurValue.controlId = props.column.controlId;
            }
        }
    };

    const handleBlur = (event) => {
        const spinnerName = `vgoSpinner-${props.settings.Id}`

        if (event.relatedTarget && event.relatedTarget.attributes && (spinnerName === event.relatedTarget.name) &&  event.relatedTarget.attributes.dataskip) {

        }
        else {
            
            const floatValue = parseFloat(values.numberFormat);
            if (floatValue !== value) {
                props.onChange(props.column.isMandatory,event, floatValue);
                blurValue.value = false;
                fromSpiner = false;
            }
        }
        
    };
    const onKeyDown = (e) => {
        
        if (e.key === "ArrowUp") {
            
            e.preventDefault();
            IncNum();
        }
        else if (e.key === "ArrowDown") {

            e.preventDefault();
            DecNum();
        }
    }
    const controlStyle = {
        color: props.column.foreColor,
        background: props.column.backGroundColor,
        width: "100%"

    }

    

    let errorState = {};
    let requiredIndicator = "";
    let helperText = props.column.helpText;
    if (props.column.isMandatory ) {
        requiredIndicator = " *";
       const validation= props.validationSchema.filter(field => field.fieldName === props.column.fieldName && field.errorState)
        if (validation.length >0) {
            errorState.error = true;
            helperText = `${props.settings.caption} is required`;
        }
    }
    const caption = !props.settings.caption ? ' ' : props.settings.caption;
    const labelStyle = {
        color: (errorState.error) ? '#f44336' : props.column.captionColor,
        zIndex: 1
    }
    const helperTextStyle = { wordBreak: 'break-all' }
    const val=values;
const valnumber=values.numberFormat;
    return (
            <div className={classes.root}>
            <TextField
                type="tel"
                disabled={!props.column.isEditable}
                key={props.column.fieldName}
                value={values.numberFormat}
                onChange={handleChange}
                onBlur={handleBlur}
                name={props.settings.name}
                label={`${caption}${requiredIndicator}`}
                helperText={!helperText ? ' ' : helperText}
                {...errorState}
                FormHelperTextProps={{
                    style: helperTextStyle,
                }}
                id={`vgoField-${props.settings.Id}`}
                defaultValue={props.settings}
                onKeyDown={onKeyDown}
                InputProps={{
                    inputComponent: numberFormatCustom,
                    style: controlStyle,
                    endAdornment: (
                        <InputAdornment position="end">
                            <div>
                                
                                <IconButton name={`vgoSpinner-${props.settings.Id}`} ref={incrementButtonRef} disabled={!props.column.isEditable} tabIndex={-1} disableRipple={true} onClick={IncNum} sx={{ p: 0 }} dataskip="true" >
                                        <KeyboardArrowUpIcon />
                                    </IconButton>
                                
                                <IconButton name={`vgoSpinner-${props.settings.Id}`} disabled={!props.column.isEditable} tabIndex={-1} disableRipple={true} onClick={DecNum} sx={{ p: 0 }} dataskip="true" >
                                    <KeyboardArrowDownIcon />
                                </IconButton>

                            </div>
                        </InputAdornment>
                    ),
                }}
                InputLabelProps={{
                    style: labelStyle
                }}
            />
        </div>
    );
}

