//import { get, put, post, delete as del, /*head, options, patch,*/ } from 'axios';
import axios from 'axios';
const get = axios.get
const post = axios.post
const put = axios.put
const del = axios.delete
const clientApi = {
    GetCurrentUtcDate: () => get('api/ReportDefinition/GetCurrentUtcDate'),
    callBizweaver: (url, parameter, postHeaders) => post(url, parameter, { headers: postHeaders }),
    
   };

export default clientApi;
