import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import * as Enums from 'client/components/Common/Enum';

import Button from 'client/components/Form/Controls/Button';

import ControlPlaceHolder from 'client/components/Form/Controls/ControlPlaceHolder';
import cloneDeep from 'lodash/cloneDeep';

import LayoutObjectHeader from 'client/components/Common/LayoutObjectHeader'
import { BackdropLoading } from 'client/components/Loading';
import { getDeviceType, addDate, dateDifference, checkIfObjectAreEqual, formatDate, setTabTitle } from 'client/components/Common/Utility'
import GridControl from './Grid';

import TextField from '@mui/material/TextField';

import { formTheme } from 'client/components/Form/theme/formTheme';
import { withStyles } from '@mui/styles';
import { alertHandlerAction } from 'client/store/AlertHandler';
import ColumnTemplate from 'client/components/DataView/ColumnTemplate'
import axios from 'axios';
import Cookies from 'universal-cookie';
import { ColumnDirective } from '@syncfusion/ej2-react-grids';
import { callBizweaverAction } from 'client/api/clientActions';
import AppBar from '@mui/material/AppBar';
const cookies = new Cookies();

const mathExpression = require('math-expression-evaluator');
let dynamicColumns = [];
let gridDataSource = [];
let actionCallStatus = true;
let buttonType = '';
let formAction = [];
let confirmDelete = false;
let isFormData = false;
const deviceType = getDeviceType();
let lookupLists = [];
let saveButtonType = '';
const dateSaveformat = "YYYY-MM-DDTHH:mm:ss";
const initialState = {
    formId: 0,
    formTitle: '',
    formName: '',
    instructions: '',
    formDefinition: { rows: [] },
    showMessage: false,
    message: '',
    messageButtonType: '',
    messageTitle: '',
    cancelButtonName: '',
    buttonDefinition: { rows: [] },
    loadingForm: true,
    dataModel: [],
    lookupVisible: false,
    lookupFields: [],
    gridDataModel: [],
    primaryKeyField: '',
    primaryKeyGridField: '',
    actionDataModel: [],
    userId: 0,
    signField: '',
    isUpdate: false,
    fileList: [],
    primaryKeyFieldValue: '',
    fileExtensions: '',
    validationSchema: { header: [], detail: [] },
    hasIdentity: true,
    actionMappedKeySettings: [],
    isReadOnly: false,
    newStaticValueList: [],
    clearFileControl: false,
    totalFormFileSize: 0,
    allowedFormFileSize: 20,
    isPublic: false,
    gridDropDownList: [],
    retrievedDataModel: {},
    gridModelUpdated: false,
    currentControl: { currentControlName: "" },
    dependentFieldMapping: [],

}
class form extends React.Component {
    constructor(props) {
        super(props);
        this.submitButton = React.createRef();
        this.state = initialState;
        this.calculationLaunchField = "";
        this.defaultHeaderDataModel = {};
        this.formFiles = [];
    }
    componentDidMount() {

        this.getFormDefinition(this.props.match.params.id);
        document.removeEventListener('keydown', this.handleActionMappedKey, false);
        document.addEventListener('keydown', this.handleActionMappedKey);
    }

    componentWillUnmount() {
       document.removeEventListener('keydown', this.handleActionMappedKey,false);
    }
    clearModelValue = (updateState) => {
        let formHeaderModel = this.setDataModel(this.state.formDefinition)
        if (updateState) {
            this.setState({ dataModel: formHeaderModel, gridDataModel: [] })
        }
        return formHeaderModel;
    }
    getInitialData = async (newStaticValueList, primaryFieldValue, loadNew, clearActionParameters) => {
        if (!this.state.formId) {
            return;
        }
        //Action Parameters
        let search = clearActionParameters ? "" : window.location.search;
        if (search) {
            search = decodeURI(search.slice(2));
        }

        this.hideAlert();
        let updateArray = this.setDataModel(this.state.formDefinition);
        const hasFormControl = Object.keys(updateArray).length > 0;

        this.setState({
            loadingForm: true,
            clearFileControl: true,
            gridModelUpdated: false,
            initialData: false,
        });
        primaryFieldValue = loadNew ? "" : !primaryFieldValue ? this.state.primaryKeyFieldValue : primaryFieldValue;
        newStaticValueList = loadNew ? this.state.newStaticValueList : newStaticValueList;
        let url = "api/FormData/GetInitialData";
        let versagoCookie = cookies.get('Versago');

        if (versagoCookie === undefined) {
            url = "api/FormData/GetPublicInitialData";
        }
        let parameters = { FormId: this.state.formId, PrimaryField: this.state.primaryKeyField, PrimaryFieldValue: primaryFieldValue, ActionLinkParameter: search };
        axios.post(url, parameters)
            .then(response => {
                if (response.status === 200) {
                    let formData = response.data.FormData
                    let detailData = response.data.DetailsData
                    let updateDataModel = updateArray;
                    let updateGridDataModel = !detailData ? [] : detailData;
                    let fileList = !response.data.Files ? [] : response.data.Files;
                    const hasIdentity = response.data.HasIdentity;
                    const gridDropDownList = response.data.DataSourceList;
                    for (var item in formData) {
                        if (formData.hasOwnProperty(item)) {
                            updateDataModel[item] = formData[item];
                        }
                    }
                    const totalFormFileSize = this.setUploadFileList(fileList, false);

                    if (fileList.length > 0) {
                        this.setFileInfoInDataModel(updateDataModel);
                    }
                    if (!primaryFieldValue && response.data.PrimaryFieldValue) {
                        primaryFieldValue = parseInt(response.data.PrimaryFieldValue);
                    }
                    let formUpdate = !(!primaryFieldValue)
                    if (primaryFieldValue && !response.data.PrimaryFieldValue) // failed to get data for entered primarykey
                    {
                        formUpdate = false;
                        primaryFieldValue = updateDataModel[this.state.primaryKeyField];
                        this.showAlert("Record not found.", false)
                    }


                    this.setState(
                        {
                            isUpdate: formUpdate,
                            hasIdentity: (formData.hasIdentity === undefined ? (hasIdentity !== undefined) ? hasIdentity : this.state.hasIdentity : formData.hasIdentity),
                            primaryKeyFieldValue: primaryFieldValue,
                            dataModel: updateDataModel,
                            gridDataModel: updateGridDataModel,
                            fileList,
                            loadingForm: false,
                            clearFileControl: fileList.length === 0,
                            totalFormFileSize,
                            newStaticValueList,
                            validationSchema: this.clearValidationSchema(),
                            gridDropDownList: gridDropDownList,
                            retrievedDataModel: { ...updateDataModel },

                            initialData: true,
                            hasFormControl,
                        })

                }
                else {

                    this.setState({ loadingForm: false, initialData: false })
                }
            })

            .catch(() => {
                this.setState({ loadingForm: false, initialData: false, hasFormControl })
                if (this.state.hasFormControl) {
                    this.showAlert("Form data loading failed.", false)
                }
            });

    }
    componentDidUpdate(nextProps, nextState) {
        if (this.props.match.params.id !== nextProps.match.params.id) {
            this.setState({ lookupVisible: false, dataModel: {} });
            this.setState(initialState);
            this.getFormDefinition(this.props.match.params.id);
        }

        if (nextState.primaryKeyFieldValue !== this.state.primaryKeyFieldValue) {
            saveButtonType = '';
        }
    }
    getFormDefinition = async (formId) => {
        let isPublic = false;
        let url = `api/FormDefinition/GetFormDefinition/${formId}`;
        let versagoCookie = cookies.get('Versago');

        if (versagoCookie === undefined) {
            isPublic = true;
            url = `api/FormDefinition/GetPublicFormDefinition/${formId}`;
        }
        var response = await window.fetch(url);
        if (response.status === 200) {

            const formDefinition = await response.json();

            this.setState({
                formId: formId,
                formTitle: formDefinition.formTitle,
                formName: formDefinition.formName,
                instructions: formDefinition.instructions,
                formDefinition: formDefinition.formLayout,
                buttonDefinition: formDefinition.buttonLayout,
                loadingForm: false,
                primaryKeyField: formDefinition.primaryKeyField,
                primaryKeyGridField: formDefinition.primaryKeyGridField,
                formulaSettings: { header: formDefinition.formulaList, detail: formDefinition.formulaDetailList },
                userId: formDefinition.userId,
                fileExtensions: formDefinition.fileExtensions,
                allowedFormFileSize: formDefinition.formFileSize,
                validationSchema: formDefinition.validationSchema,
                actionMappedKeySettings: formDefinition.actionMappedKeySettings,
                isReadOnly: formDefinition.isReadOnly,
                layoutType: formDefinition.layoutType,
                newStaticValueList: [],
                isPublic: isPublic,
                favoriteStatus: formDefinition.favoriteStatus,
                dependentFieldMapping: [],
            });

            this.getInitialData([]);

            if (formDefinition.hasUnAuthorizedList) {
                this.props.actions.alertHandler.showWarningMessage(" You will not have permission to use some or all look-up lists or menus on this page. Some may be required entries");
            }


        } else {

            const favSettings = (response.status === 409 && isPublic === false) ?
                { favoriteStatus: Enums.FavoriteStatus.Delete, removedFormId: parseInt(formId) } : { favoriteStatus: "" }
            this.setState({
                formId: 0,
                formTitle: '',
                formDefinition: { rows: [] },
                buttonDefinition: { rows: [] },
                loadingForm: false,
                isPublic: isPublic,
                ...favSettings,
                dependentFieldMapping: [],
            });


            if (response.status === 401) {
                this.showAlert("The form selected is not available to this account.");

            }
            else if (response.status !== 409) {
                this.showAlert("Form loading failed", false);
            }
        }

    };

    setColumnTemplate = (data, column) => {
        return (<ColumnTemplate data={data} column={column} />);
    }

    setGridDataTableColumn = (formData, data) => {
        //formData = this.state.gridDataModel;
        gridDataSource = data;
        let columnDefinition = []
        if (data.length > 0) {
            dynamicColumns = data[0].columns.filter(filter => (filter.fieldName !== "" && filter.isVisible === true))
                .map((column) => {
                    let detailColumn = column;
                    detailColumn["fieldFormat"] = column.format;
                    detailColumn["kpi"] = [];
                    const col = <ColumnDirective field={column.fieldName} headerText={column.fieldName} width='120' template={this.setColumnTemplate(data, detailColumn)}></ColumnDirective>
                    columnDefinition.push(col);
                    return columnDefinition;
                });
        }
        return columnDefinition;
    }
    setColumnTemplate = (data, column) => {
        return (<ColumnTemplate data={data} column={column} />);
    }
    generateFormLayout = (layoutRows) => {
        let isSubRow = false;
        let rowIndex = 0;
        lookupLists = [];
        const { classes } = this.props;
        const labelClassName = `${classes.customLabel} ${classes.fileUploadCaption}`;
        let removePadding = (deviceType === Enums.DeviceType.Desktop) ? {} : { paddingTop: "0px !important" };
        let removeElement = (deviceType === Enums.DeviceType.Desktop) ?
            (<TextField InputProps={{
                readOnly: true,
                disableUnderline: true,
                disabled: true,
            }} helperText={' '} value={" "} label={' '} />) :
            (<div></div>);

        const rows = layoutRows.map((row) => {
            rowIndex++;
            let lookupList = {};
            let columnIndex = 0;
            let width = 0;
            let columns = row.columns.map((column) => {

                if (column.width) {
                    width = column.width
                }
                else {
                    width = Math.round(12 / row.columns.length);
                }
                columnIndex++;
                let controlPlaceHolder = <TextField InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                    disabled: true,
                }} helperText={' '} value={" "} label={' '} />
                if (column.subRows.length > 0) {

                    if (column.rowColumnId.indexOf('grd') > -1) {
                        return (<Grid item pl={3} xs={12} sm={12} md={width}>{this.renderGridLayout(column)}</Grid>)
                    } else {
                        controlPlaceHolder = (<Grid spacing={1} container key={"Column" + columnIndex} >{this.generateFormLayout(column.subRows)} </Grid>)
                        isSubRow = true;
                    }

                }
                else {

                    if (column.listParameterFields.length > 0) {
                        lookupList.param = column.listParameterFields;
                        let listOutput = column.controlType !== Enums.ControlType.DropdownList ? column.listOutputFields.filter(output => (output.controlName !== column.fieldName)) : column.listOutputFields;
                        lookupList.output = listOutput;

                        lookupLists.push(lookupList);
                    }


                    controlPlaceHolder = <ControlPlaceHolder control={column}
                        dataModel={this.state.dataModel}
                        validationSchema={this.state.validationSchema.header}
                        onControlChange={this.onControlChange}
                        onControlChangeLoadData={this.onControlChangeLoadData}
                        showMessageBox={this.showAlert}
                        setLookupListOutputValue={this.setLookupListOutputValue}
                        newStaticValueList={this.state.newStaticValueList}
                        classes={this.props.classes}
                        callFormAction={this.callFormAction}
                        setFormFiles={this.setFormFiles}
                        fileSettings={{
                            currentfileList: this.state.fileList,
                            clearFileControl: this.state.clearFileControl,
                            allowedExtensions: this.state.fileExtensions,
                            totalFormFileSize: this.state.totalFormFileSize,
                            allowedFormFileSize: this.state.allowedFormFileSize,
                            fileLabelClassName: labelClassName,
                            formId: this.state.formId,
                        }}
                        isPublic={this.state.isPublic}
                        retrievedDataModel={this.state.retrievedDataModel}
                        currentControl={this.state.currentControl}
                        dependentFieldMapping={this.state.dependentFieldMapping}
                    />

                }

                if (isSubRow) {
                    isSubRow = false;
                    return (<Grid item xs={12} sm={12} md={width} key={"Column" + columnIndex} >{controlPlaceHolder} </Grid>);
                } else {
                    if (!(!column.controlId) && column.isVisible && column.controlType !== Enums.ControlType.Select) {
                        return (<Grid item xs={12} sm={12} md={width} key={"Column" + columnIndex} >{controlPlaceHolder} </Grid>);
                    }
                    else {
                        return (<Grid item xs={12} sm={12} md={width} sx={{ ...removePadding }} key={"Column" + columnIndex} >{removeElement}</Grid>)
                    }
                }
            });
            return <Grid container item spacing={3} key={"Row" + rowIndex} > {columns}  </Grid>
        });

        return rows;
    }
   
    getDefaultValueFormatted = (column) => {
        var defaultValue = 0;
      
                if(column.controlType === Enums.ControlType.NumericTextBox)
                            {
                               
                    if (column.precision > 0) {
                        const toFixed = (n, fixed) => ~~(Math.pow(10, fixed) * n) / Math.pow(10, fixed);
                        defaultValue = toFixed(column.defaultValue, column.precision);// parseFloat(column.defaultValue).toFixed(column.precision)
                      
                    } else {
                        defaultValue = parseInt(column.defaultValue)
                    }
                            }
                            else
                            {
                    defaultValue= column.defaultValue;
        }
        return defaultValue;
     }
    setDataModel = (data) => {
        let modelArray = [];
        this.defaultHeaderDataModel = {};
        for (var i = 0; i < data.rows.length; i++) {
            let columns = data.rows[i].columns;
            for (var j = 0; j < columns.length; j++) {
                let column = columns[j];
                if (column.fieldName) {

                    if (column.defaultValue.toUpperCase() === '$GETDATE()') {
                        var currentDate = new Date();
                        modelArray[column.fieldName] = currentDate;
                        this.defaultHeaderDataModel[column.fieldName] = currentDate;
                    } else {
                        var defaultValue=this.getDefaultValueFormatted(column);
                        modelArray[column.fieldName] = defaultValue;//column.defaultValue;
                        this.defaultHeaderDataModel[column.fieldName] = defaultValue;
                    }
                }

                if (columns[j].subRows.length > 0) {
                    if (column.rowColumnId.indexOf('grd') === -1) {
                        modelArray = this.setSubRowDataModel(columns[j].subRows, modelArray);
                    }
                }
            }
        }
        // this.setState({ dataModel: modelArray });
        return modelArray;
    }
    setSubRowDataModel = (data, modelArray) => {

        for (var i = 0; i < data.length; i++) {
            let columns = data[i].columns;
            for (var j = 0; j < columns.length; j++) {
                let column = columns[j];
                if (column.fieldName !== '') {

                    if (column.defaultValue.toUpperCase() === '$GETDATE()') {
                        var currentDate = new Date();
                        modelArray[column.fieldName] = currentDate;
                        this.defaultHeaderDataModel[column.fieldName] = currentDate;
                    } else {
                       /// modelArray[column.fieldName] = column.defaultValue;
                        //this.defaultHeaderDataModel[column.fieldName] = column.defaultValue;

var defaultValue=this.getDefaultValueFormatted(column);
                        modelArray[column.fieldName] = defaultValue;//column.defaultValue;
                        this.defaultHeaderDataModel[column.fieldName] = defaultValue;

                    }
                }



            }

        }

        //        this.setState({ dataModel: modelArray });
        return modelArray;



    }
    getButtonLayout = (data) => {
        const { classes } = this.props;
        const otherButtonClassName = classes.otherButton_Client;
        const saveButtonClassName = classes.saveButton_Client;
        const columns = data.rows.sort((a, b) => {
            return a.displayOrder - b.displayOrder;
        }).filter(button => button.isVisible).map((column) => {
            const isDisabled = !column.isEditable;
            return (<Grid item >
                <Button
                    saveButtonClassName={isDisabled ? classes.saveButton_Client_Basic : saveButtonClassName}
                    otherButtonClassName={isDisabled ? classes.otherButton_Client_Basic : otherButtonClassName}
                    onClick={this.callFormAction}
                    column={column}
                    disabled={isDisabled}
                    caption={column.caption} />
            </Grid>);


        });

        return (<Grid container spacing={1} className={classes.actionButtonGroup} justifyContent="flex-end">
            {columns}</Grid>);

    }
    handleClose = () => {

        this.setState({ showMessage: false });

    }
    handleContinue = () => {
        this.setState({ showMessage: false });
        if (this.state.cancelButtonName === "Cancel") {
            confirmDelete = true;
            this.callFormAction(formAction.action, formAction.controlId, formAction.controlType);
        }

    };
    showMessageBox = (message, title) => {
        let buttonType;
        let cancelButtonName = "Ok"
        if (title === 'Error') {
            buttonType = Enums.ButtonType.Cancel;
        }
        else if (title === 'Success') {
            buttonType = Enums.ButtonType.Ok;
        }
        else {
            buttonType = Enums.ButtonType.OkCancel;
            cancelButtonName = "Cancel"
        }

        this.setState({ showMessage: true, message: message, messageTitle: title, messageButtonType: buttonType, cancelButtonName: cancelButtonName });

    }
    showDeleteConfirmMessageBox = (action, controlId, controlType) => {
        this.props.actions.alertHandler.showWarningMessageWithAction
            ("Are you sure you want to delete this record?", [this.continueDelete.bind(this, action, controlId, controlType), this.setShowConfirmDeleteFlag.bind(this, false)]);

    }

    continueDelete = (action, controlId, controlType) => {
        this.setShowConfirmDeleteFlag(true);
        this.callFormAction(action, controlId, controlType);
    }

    showConfirmActionLink = (history, isClose) => {

        var isObjectEqual = checkIfObjectAreEqual(this.state.retrievedDataModel, this.state.dataModel);

        if (!isObjectEqual || this.state.gridModelUpdated || this.checkFileUploadChange()) {
            this.props.actions.alertHandler.showWarningMessageWithAction
                ("Do you want to save the data?", [this.noActionActionLinkWindow.bind(), isClose ? this.closeActionLinkWindow.bind(this, isClose) : this.backActionLinkWindow.bind(this, history)]);
        } else {
            if (isClose) {
                this.closeActionLinkWindow(true);
            } else {
                this.backActionLinkWindow(history);
            }
        }
    }
    checkFileUploadChange = () => {

        let uploadFiles = this.formFiles;
        if (uploadFiles.length > 0 || this.state.fileList.length > 0) {
            let existingFileCount = 0, newFileCount = 0;
            for (let i = 0; i < uploadFiles.length; i++) {
                for (let j = 0; j < uploadFiles[i].fileList.length; j++) {
                    const file = uploadFiles[i].fileList[j].file;
                    if (!file.fileId) {
                        newFileCount++;
                    }
                    else {
                        existingFileCount++;
                    }
                }
            }
            if (!(existingFileCount === this.state.fileList.length && newFileCount === 0)) {
                return true;
            }
        }
        return false;
    }
    closeActionLinkWindow = (isClose) => {
        window.close();


    }
    backActionLinkWindow = (history) => {
        history.goBack();
    }
    noActionActionLinkWindow = () => {
        var control = this.state.buttonDefinition.rows.find(b => b.controlType == "submit");
        this.callFormAction(control.action, control.controlId, control.controlType, null, true)
    }

    showAlert = (message, isSuccess) => {
        if (!isSuccess) {
            this.props.actions.alertHandler.showErrorMessage(message);
        }
        else {
            this.props.actions.alertHandler.showSuccessMessage(message);
        }

    }
    hideAlert = () => {
        this.props.actions.alertHandler.hideAlert();

    }
    validateFormData = () => {
        const hasFormControl = this.state.hasFormControl;

        let validationSchema = this.state.validationSchema;
        let errorStatus = false;
        const validationHeaderSchema = validationSchema.header.map((field) => {
            if (field.controlType === Enums.ControlType.CheckBox) {
                if (this.state.dataModel[field.fieldName] === "0" || this.state.dataModel[field.fieldName] === 0 || !this.state.dataModel[field.fieldName] || field.errorStatus) {
                    field.errorState = true;
                    field.touched = true;
                    errorStatus = true;
                }
            }
            else {
                if (this.state.dataModel[field.fieldName] !== 0 && (!this.state.dataModel[field.fieldName] || field.errorStatus)) {
                    field.errorState = true;
                    field.touched = true;
                    errorStatus = true;
                }
            }

            return field;
        });
        validationSchema.header = validationHeaderSchema;
        validationSchema.errorStatus = errorStatus;
        this.setState({ validationSchema: validationSchema });
        if (errorStatus) {
            this.showAlert("Please correct the indicated items", false)
        }
        else {
            if (this.state.isUpdate && this.state.dataModel[this.state.primaryKeyField] !== this.state.primaryKeyFieldValue) {
                errorStatus = true;
                this.showAlert(`Save action cannot proceed since ${this.state.primaryKeyField} is changed`, false)
            }
            else if (!hasFormControl) {
                this.showAlert("A Save action can not be performed for a form with no inputs", false)
                errorStatus = true;
            }
        }

        return errorStatus;
    }
    clearValidationSchema = () => {
        let validationSchema = this.state.validationSchema;
        validationSchema.header = validationSchema.header.map(field => {
            field.errorState = false;
            field.errorStatus = false;
            field.touched = false;
            return field;
        });
        validationSchema.detail = validationSchema.detail.map(field => {
            field.errorState = false;
            field.errorStatus = false;
            field.touched = false;
            return field;
        });
        return validationSchema;
    }
    setShowConfirmDeleteFlag = (show) => {
        confirmDelete = show;
    }
    onControlChangeLoadData = (isRequired, event, value, mappedFieldList, mappedFieldValue, skipFormula) => {
        let updatedModel = this.state.dataModel;
        let validationSchema = this.state.validationSchema;
        updatedModel[event.target.name] = value;
        let fieldName = event.target.name;
        if (isRequired && (value === '' || Number.isNaN(value))) {
            const validationHeaderSchema = validationSchema.header.map((field) => {

                if (field.fieldName === fieldName) {
                    field.errorState = true;
                }
                return field;
            });
            validationSchema.header = validationHeaderSchema;
            this.setState({ dataModel: updatedModel, validationSchema: validationSchema });
        }
        else {
            if (value === '' || Number.isNaN(value)) {
                this.setState({ dataModel: updatedModel });
            }
            else {
                this.getInitialData([], value, false, true)
            }
        }
    }
    onControlChange = (isRequired, event, value, mappedFieldList, mappedFieldValue, skipFormula, skipValidation, isLookUp) => {

        let currentControlName = this.state.currentControl.currentControlName;
        let dependentFieldMapping = this.state.dependentFieldMapping ? this.state.dependentFieldMapping : [];
        if (event.bubbles) {
            currentControlName = event.target.name
        }
        if (isLookUp) {
            currentControlName = event
        }

        let updatedModel = this.state.dataModel;
        let validationSchema = this.state.validationSchema;
        this.calculationLaunchField = "";
        let fieldName = "";
        let oldModel = { ...this.state.dataModel };

        if (value !== oldModel[fieldName]) {
            for (let lookupList of lookupLists) {
                for (let param of lookupList.param) {
                    if (fieldName === param.controlName) {
                        updatedModel = this.clearLookupListDependency(lookupList.output);
                    }
                }
            }
        }
        if (typeof event === 'string') {
            updatedModel[event] = value;

            if (mappedFieldList && mappedFieldList.length > 0) { // for dropdownlist
                for (var i = 0; i < mappedFieldList.length; i++) {
                    const controlName = mappedFieldList[i].controlName;
                    const controlValue = value === "" ? value : mappedFieldValue[mappedFieldList[i].reportField];
                    updatedModel[controlName] = controlValue;

                    const dependentFieldIndex = dependentFieldMapping.findIndex(field => field.dependencyField === controlName)
                    if (dependentFieldIndex !== -1) {
                        dependentFieldMapping = dependentFieldMapping.splice(dependentFieldIndex, 1);
                    }
                    dependentFieldMapping.push({ dependencyField: controlName, value: controlValue, parentField: event, parentFieldValue: value });
                }
            }
            if (!skipFormula) {
                this.applyFormula(event, updatedModel, false);
            }

            fieldName = event;
        } else {
            if (event.type === 'blur') {
                updatedModel[event.target.name] = value;
                if (!value) {
                    if (mappedFieldList && mappedFieldList.length > 0) { // clear dependecny field value
                        for (var i = 0; i < mappedFieldList.length; i++) {
                            const controlName = mappedFieldList[i].controlName;

                            if (currentControlName !== controlName) {
                                updatedModel[controlName] = "";
                            }
                        }
                    }
                }
            }
            else {
                updatedModel[event.target.name] = event.target.value;
            }
            if (!skipFormula) {
                this.applyFormula(event.target.name, updatedModel, false);
            }
            fieldName = event.target.name;

            const dependentFieldIndex = dependentFieldMapping.findIndex(field => field.dependencyField === fieldName)
            if (dependentFieldIndex !== -1) {
                dependentFieldMapping = dependentFieldMapping.splice(dependentFieldIndex, 1);
            }
        }

        if (isRequired && this.state.initialData) {
            const validationHeaderSchema = validationSchema.header.map((field) => {

                if (field.controlType === Enums.ControlType.CheckBox) {
                    if (field.fieldName === fieldName) {
                        if (value === "0" || value === 0 || value === '' || Number.isNaN(value)) {
                            if (!skipValidation)
                                field.errorState = true;
                        }
                        else {
                            field.errorState = false;
                            field.touched = true;
                        }
                    }


                }
                else {

                    if (field.fieldName === fieldName) {
                        if (value === '' || Number.isNaN(value)) {
                            if (!skipValidation)
                                field.errorState = true;
                        }
                        else {
                            field.errorState = false;
                            field.touched = true;
                        }
                    }
                    else if (mappedFieldList && mappedFieldList.length > 0) {
                        const mappedFieldIndex = mappedFieldList.findIndex(control => control.controlName === field.fieldName)
                        if (mappedFieldIndex !== -1) {
                            const mappedValue = updatedModel[field.fieldName];
                            if (mappedValue) {
                                field.errorState = false;
                                field.touched = true;
                            }
                        }
                    }
                }


                return field;
            });

            validationSchema.header = validationHeaderSchema;

        }
        else if (!isRequired && mappedFieldList && mappedFieldList.length > 0) {

            const validationHeaderSchema = validationSchema.header.map((field) => {

                const mappedFieldIndex = mappedFieldList.findIndex(control => control.controlName === field.fieldName)
                if (mappedFieldIndex !== -1) {
                    const mappedValue = updatedModel[field.fieldName];
                    if (mappedValue) {
                        field.errorState = false;
                        field.touched = true;
                    }
                }




                return field;
            });

            validationSchema.header = validationHeaderSchema;
        }

        this.setState({
            dataModel: updatedModel, validationSchema: validationSchema,
            currentControl: { currentControlName: currentControlName, isLookUp: isLookUp, lookUpRowData: mappedFieldValue, mappedFieldList: mappedFieldList },
            dependentFieldMapping: dependentFieldMapping
        });




    }
    applyFormula = (fieldName, headerModel, fromDetail, gridModel) => {

        const formulaList = this.state.formulaSettings.header;
        let result;
        if (fromDetail) {
            result = formulaList.filter(formula => formula.formulaType === Enums.FormulaType.AggregateSum);
        } else {
            result = formulaList.filter(formula => formula.fieldName === fieldName);
        }
        for (let i = 0; i < result.length; i++) {
            const formulaObject = result[i];
            if (formulaObject.formulaType === Enums.FormulaType.DateDifference || formulaObject.formulaType === Enums.FormulaType.DateAdd) {
                this.applyDateFormula(formulaObject, headerModel)

            } else {
                let formula = formulaObject.formula;
                const precision = formulaObject.fieldPrecision;
                for (let j = 0; j < formulaObject.dependentFieldList.length; j++) {
                    const fieldName = formulaObject.dependentFieldList[j].fieldName;
                    let value;
                    if (formulaObject.dependentFieldList[j].isSum) {
                        const gridDataModel = (fromDetail) ? gridModel : this.state.gridDataModel;
                        if (formula.includes('SUM')) {
                            value = gridDataModel.reduce((total, gridData) => total + gridData[fieldName], 0);
                            if (isNaN(value)) {
                                value = 0;
                            }
                            formula = formula.replace(`SUM([${fieldName}])`, value);
                        } else {
                            value = gridDataModel.reduce((total, gridData) => total + gridData[fieldName], 0) / gridDataModel.length;
                            if (isNaN(value)) {
                                value = 0;
                            }
                            formula = formula.replace(`AVG([${fieldName}])`, value);
                        }
                    } else {

                        value = headerModel[fieldName];
                        if (value === null || value === "" || value === undefined || isNaN(value)) {
                            value = 0;
                        } else {
                            value = parseFloat(value);
                        }
                        formula = formula.replace(`[${fieldName}]`, value);
                    }


                }
                if (this.calculationLaunchField === "") {
                    this.calculationLaunchField = formulaObject.fieldName;
                }
                let calculatedValue = mathExpression.eval(formula);
                if (precision >= 0 && !isNaN(calculatedValue)) {
                    calculatedValue = parseFloat(calculatedValue.toFixed(precision))
                }
                
                headerModel[formulaObject.formulaField] = calculatedValue;
                if (this.calculationLaunchField !== formulaObject.formulaField) {
                    this.applyFormula(formulaObject.formulaField, headerModel, false)
                }
            }

        }

    }

    applyDateFormula = (formulaObject, detailModel) => {
        let formula = formulaObject.formula;
        if (formulaObject.formulaType === Enums.FormulaType.DateDifference) {
            let startDate = detailModel[formulaObject.dependentFieldList[0].fieldName]
            let endDate = detailModel[formulaObject.dependentFieldList[1].fieldName]
            if (startDate !== null &&
                startDate !== "" &&
                startDate !== undefined &&
                endDate !== null &&
                endDate !== "" &&
                endDate !== undefined) {
                if (this.calculationLaunchField === "") {
                    this.calculationLaunchField = formulaObject.fieldName;
                }
                if (formula.toLowerCase().startsWith("datediff")) {
                    const newValue = dateDifference(startDate, endDate, 'days');
                    detailModel[formulaObject.formulaField] = newValue;
                }
                else if (formula.toLowerCase().startsWith("monthdiff")) {
                    const newValue = dateDifference(startDate, endDate, 'months');
                    detailModel[formulaObject.formulaField] = newValue;
                }
                else if (formula.toLowerCase().startsWith("yeardiff")) {
                    const newValue = dateDifference(startDate, endDate, 'years');
                    detailModel[formulaObject.formulaField] = newValue;
                }
                else if (formula.toLowerCase().startsWith("hourdiff")) {
                    const newValue = dateDifference(startDate, endDate, 'hours');
                    detailModel[formulaObject.formulaField] = newValue;
                }
                else if (formula.toLowerCase().startsWith("minutediff")) {
                    const newValue = dateDifference(startDate, endDate, 'minutes');
                    detailModel[formulaObject.formulaField] = newValue;
                }
                if (this.calculationLaunchField !== formulaObject.formulaField) {
                    this.applyFormula(formulaObject.formulaField, detailModel)
                }
            }

            //dateDifference

        }
        else {
            let dateValue, numberValue;
            if (formulaObject.dependentFieldList.length === 2) {
                dateValue = detailModel[formulaObject.dependentFieldList[1].fieldName];
                numberValue = detailModel[formulaObject.dependentFieldList[0].fieldName];

            }
            else {
                dateValue = detailModel[formulaObject.dependentFieldList[0].fieldName];
                numberValue = formula.substring(formula.indexOf('(') + 1, formula.indexOf(','));
                numberValue = parseInt(numberValue);
            }
            if ((numberValue > 0 || numberValue < 0) && dateValue !== null &&
                dateValue !== "" &&
                dateValue !== undefined) {
                if (this.calculationLaunchField === "") {
                    this.calculationLaunchField = formulaObject.fieldName;
                }
                if (formula.startsWith("AddDay")) {

                    const newValue = addDate(dateValue, numberValue, 'days');
                    detailModel[formulaObject.formulaField] = newValue;
                } else if (formula.startsWith("AddMonth")) {

                    const newValue = addDate(dateValue, numberValue, 'months');
                    detailModel[formulaObject.formulaField] = newValue;
                }
                else if (formula.startsWith("AddYear")) {

                    const newValue = addDate(dateValue, numberValue, 'years');
                    detailModel[formulaObject.formulaField] = newValue;
                }
                else if (formula.startsWith("AddHour")) {

                    const newValue = addDate(dateValue, numberValue, 'hours');
                    detailModel[formulaObject.formulaField] = newValue;
                }
                else if (formula.startsWith("AddMinutes")) {

                    const newValue = addDate(dateValue, numberValue, 'minutes');
                    detailModel[formulaObject.formulaField] = newValue;
                }
                if (this.calculationLaunchField !== formulaObject.formulaField) {
                    this.applyFormula(formulaObject.formulaField, detailModel)
                }
            }
        }
    }
    clearLookupListDependency = (outputFields) => {
        let updatedModel = this.state.dataModel;
        for (var i = 0; i < outputFields.length; i++) {
            let controlName = outputFields[i].controlName;
            updatedModel[controlName] = "";
            this.applyFormula(controlName, updatedModel, false);
        }
        return updatedModel;
    }
    setLookupListOutputValue = (report, outputFields) => {
        let updatedModel = this.state.dataModel;
        for (var i = 0; i < outputFields.length; i++) {
            let controlName = outputFields[i].controlName;
            let value = report[outputFields[i].reportField];
            updatedModel[controlName] = value;
            this.applyFormula(controlName, updatedModel, false);
        }
        this.setState({ dataModel: updatedModel });
    }
    showLookupList = (status, lookupFields) => {
        this.setState({ lookupVisible: status, lookupFields: lookupFields });
    }
    updateGridModel = (gridDataList, index, status) => {
        this.calculationLaunchField = "";
        this.applyFormula("", this.state.dataModel, true, gridDataList);
        this.setState({ gridDataModel: gridDataList, gridModelUpdated: true });
    }

    setFormFiles = (controlId, fileList, add, setState, isRequired, fieldName) => {
        let totalFormFileSize = this.state.totalFormFileSize;
        let allFilesRemoved = false;
        if (add) {
            const fileSettings = this.formFiles.find(file => file.controlId === controlId);
            if (!fileSettings) {
                this.formFiles.push({
                    controlId: controlId,
                    fileList: fileList.map((item, index) => {
                        totalFormFileSize += parseInt(item.size);
                        return {
                            key: item.key,
                            file: item,
                        }
                    }),
                });
            }
            else {
                //const fileCount = fileSettings.fileList.length;
                fileSettings.fileList.push(...fileList.map((item, index) => {
                    totalFormFileSize += parseInt(item.size);
                    return {
                        key: item.key,
                        file: item,
                    }
                }))
            }
        }
        else {
            const fileSettings = this.formFiles.find(file => file.controlId === controlId);
            if (fileSettings) {
                for (let file of fileList) {
                    const deleteIndex = !file.key ? fileSettings.fileList.findIndex(fileObj => fileObj.file.name === file.name) : fileSettings.fileList.findIndex(fileObj => fileObj.key === file.key)
                    if (deleteIndex !== -1) {
                        const size = fileSettings.fileList[deleteIndex].file.size;
                        fileSettings.fileList.splice(deleteIndex, 1)
                        totalFormFileSize = totalFormFileSize - parseInt(size);

                        allFilesRemoved = fileSettings.fileList.length === 0;

                    }
                }
            }
        }
        if (setState) {
            let updatedModel = this.state.dataModel;
            let validationSchema = this.state.validationSchema;
            if (isRequired) {
                const errorState = add ? false : allFilesRemoved;
                const validationHeaderSchema = validationSchema.header.map((field) => {

                    if (field.fieldName === fieldName) {
                        field.errorState = errorState;
                    }
                    return field;
                });
                validationSchema.header = validationHeaderSchema;
                updatedModel[fieldName] = errorState ? "" : "has File"

            }

            this.setState({
                totalFormFileSize,
                clearFileControl: false,
                dataModel: updatedModel,
                validationSchema: validationSchema,
            })
        }

    }

    renderGridLayout = (column) => {
        const subRows = column.subRows;
        return (
            <GridControl key="gridLayout"
                buttonType={saveButtonType}
                controlId={column.controlId}
                primaryKeyFieldValue={this.state.primaryKeyFieldValue}
                primaryKeySettings={{ primaryKeyLinkField: this.state.primaryKeyGridField, primaryKeyField: this.state.primaryKeyField }}
                updateGridModel={this.updateGridModel}
                dataSource={subRows}
                showMessageBox={this.showAlert}
                dynamicColumns={dynamicColumns}
                headerDataModel={this.state.dataModel}
                gridDataModel={this.state.gridDataModel}
                formulaSettings={cloneDeep(this.state.formulaSettings.detail)}
                detailValidationSchema={this.state.validationSchema.detail}
                readOnly={this.state.isReadOnly}
                isPublic={this.state.isPublic}
                gridDropDownList={this.state.gridDropDownList}
                newStaticValueList={this.state.newStaticValueList}
                deviceType={deviceType}
            />
        );
    }
    //-------------Action-----------------------------

    callFormAction = (action, controlId, controlType, previousParameter, history) => {
        //previousParameter== saved or updated formdata
        buttonType = controlType;
        saveButtonType = controlType;
        if (buttonType === Enums.ControlType.ClearWithGetAction) {
            previousParameter = (previousParameter) ? previousParameter : this.getFormSaveActionParameter();
            this.getInitialData([], false, true, true);
        }
        if (controlType === Enums.ControlType.ActionWithConfirmClear && confirmDelete === false) {
            this.hideAlert();
            if (this.state.isUpdate && this.state.dataModel[this.state.primaryKeyField] === this.state.primaryKeyFieldValue) {
                //this.setShowConfirmDeleteFlag(true);
                this.showDeleteConfirmMessageBox(action, controlId, controlType);
            }
            else {
                this.showAlert("A Delete action can not be performed for a form with no inputs", false);
            }
            return;
        }
        this.setShowConfirmDeleteFlag(false);
        if (action.length > 0) {

            for (var i = 0; i < action.length; i++) {
                let actionItem = action[i];
                let parameters;
                let postHeaders = {}
                if (actionCallStatus === true) {


                    if (actionItem.isBizweaverService === true) {
                        previousParameter = !previousParameter ? this.getFormSaveActionParameter() : previousParameter
                        parameters = this.setBizweaverParameter(actionItem.formActionParameter, previousParameter);
                        this.requestPostBizweaverService(actionItem, parameters, action, controlId, previousParameter);
                    }
                    else {

                        if (actionItem.actionType === Enums.ActionType.Service) // webservice call
                        {
                            parameters = this.getFormSaveActionParameter();
                            parameters.EventControlType = controlType;
                            if (buttonType !== Enums.ControlType.ClearWithGetAction) {

                                if (buttonType === Enums.ControlType.ActionWithConfirmClear || !this.validateFormData()) {
                                    this.requestPost(actionItem.actionTarget, parameters, postHeaders, action, controlId, history)
                                }
                            }
                            else {
                                let actionArray = action.slice();
                                if (actionArray.length > 0) {
                                    actionArray.splice(0, 1)
                                }
                                if (actionArray.length > 0) {
                                    this.callFormAction(actionArray, controlId, "", parameters)
                                }
                            }
                        }
                        else if (actionItem.actionType === Enums.ActionType.Url) //link action
                        {

                            previousParameter = !previousParameter ? this.getFormSaveActionParameter() : previousParameter
                            const queryParameter = this.setUrlParameter(actionItem.formActionParameter, previousParameter)
                            const url = `${actionItem.actionTarget}${queryParameter}`;
                            var oDate = new Date();
                            window.open(url + oDate.getTime(), "_blank");
                            //window.open(url, "_blank");


                        }
                    }

                    break;
                }

            }

        }
    }
    requestGet = async (url, parameters, action, controlId) => {
        actionCallStatus = false;
        this.setState({ loadingForm: true })
        axios.get(url,
            {
                params: parameters
            })
            .then(response => {
                this.setState({ loadingForm: false })
                if (response.status === 200) {

                    if (buttonType != null)
                        this.ClearFormData(response);
                    actionCallStatus = true;
                    let actionArray = action.slice();
                    if (actionArray.length > 0) {
                        actionArray.splice(0, 1)
                    }
                    if (actionArray.length > 0) {
                        this.callFormAction(actionArray, controlId, buttonType)
                    }


                }
            }).catch(() => {
                this.setState({ loadingForm: false })
                if (action.length > 0) {
                    //this.showMessageBox(action[0].validateErrorMessage, "Error");
                    this.showAlert(action[0].validateErrorMessage, false);
                }
                actionCallStatus = true;

            });

    }
    requestPost = async (url, parameters, postHeaders, action, controlId, history) => {
        actionCallStatus = false;
        this.hideAlert();
        this.setState({ loadingForm: true, clearFileControl: false })

        axios.post(url, parameters,

            {
                headers: postHeaders

            }
        )
            .then(response => {
                if (response.status === 200) {

                    const actionStatus = response.data;
                    if (actionStatus.preActionStatus === "success") {
                        if (isFormData) {
                            this.requestFileUpload(actionStatus, action, controlId, parameters, this.requestPostSuccessCallback, history);
                            isFormData = false;
                        }
                        else {
                            this.requestPostSuccessCallback(actionStatus, action, controlId, parameters, history)
                        }


                    }
                    else {
                        this.setState({ loadingForm: false, clearFileControl: false })
                        if (actionStatus.preActionMessage) {
                            this.showAlert(actionStatus.preActionMessage, false);
                        }
                        else if (!action[0].hideErrorMessage) {
                            this.showAlert(action[0].validateErrorMessage, false);
                        }
                        actionCallStatus = true;
                    }

                }
                else {
                    this.setState({ loadingForm: false, clearFileControl: false })
                    if (action.length > 0 && !action[0].hideErrorMessage) {
                        this.showAlert(action[0].validateErrorMessage, false);

                    }
                    actionCallStatus = true;
                }


            }).catch(() => {
                this.setState({ loadingForm: false, clearFileControl: false })
                if (action.length > 0 && !action[0].hideErrorMessage) {
                    this.showAlert(action[0].validateErrorMessage, false);

                }
                actionCallStatus = true;

            });
    }

    requestPostSuccessCallback = (actionStatus, action, controlId, parameters, fileList, history) => {

        this.ClearFormData(actionStatus, action[0].refreshAfterSubmit, fileList, history);
        if (!action[0].hideSuccessMessage) {
            this.showAlert(action[0].validateSuccessMessage, true);
        }

        actionCallStatus = true;
        let actionArray = action.slice();
        if (actionArray.length > 0) {
            actionArray.splice(0, 1)
        }
        if (actionArray.length > 0) {
            if (buttonType === Enums.ControlType.ActionWithConfirmClear) {
                this.callFormAction(actionArray, controlId, "", parameters)
            }
            else {
                this.callFormAction(actionArray, controlId, buttonType, parameters)
            }

        }
    }
    requestPostBizweaverService = async (actionItem, parameters, action, controlId, previousParameter) => {
        actionCallStatus = false;
        await callBizweaverAction(actionItem.actionTarget, '', parameters)

            .then(response => {
                // this.setState({ loadingForm: false })
                if (response.status === 200) {


                    if (buttonType = Enums.ControlType.Button && !action[0].hideSuccessMessage) {

                        this.showAlert(action[0].validateSuccessMessage, true);

                    }
                    const actionStatus = response;

                    actionCallStatus = true;
                    let actionArray = action.slice();
                    if (actionArray.length > 0) {
                        actionArray.splice(0, 1)
                    }
                    if (actionArray.length > 0) {
                        this.callFormAction(actionArray, controlId, buttonType, previousParameter)
                    }
                }
                else {

                    if (action.length > 0 && !action[0].hideErrorMessage) {
                        this.showAlert(action[0].validateErrorMessage, false);

                    }
                    actionCallStatus = true;
                }


            }).catch(() => {
                if (action.length > 0 && !action[0].hideErrorMessage) {
                    this.showAlert(action[0].validateErrorMessage, false);

                }
                actionCallStatus = true;

            });
    }
    //setting datamodel value to json
    setJsonModelValue = (model) => {
        let modelArray = [];
        for (var item in model) {
            if (model.hasOwnProperty(item)) {
                const value = model[item]
                if (value && typeof value === 'object') {
                    const rawDate = formatDate(dateSaveformat, value)
                    modelArray.push({ FieldName: item, Value: rawDate })
                }
                else {
                    modelArray.push({ FieldName: item, Value: value })
                }

            }
        }

        //return JSON.stringify(modelArray)
        return modelArray;
    }
    setJsonDetailModelValue = (model) => {
        let detailModelArray = []
        for (let i = 0; i < model.length; i++) {
            let array = [];
            let itemModel = model[i];

            for (var item in itemModel) {
                if (itemModel.hasOwnProperty(item)) {
                    const value = itemModel[item]
                    if (value && typeof value === 'object') {
                        const rawDate = formatDate(dateSaveformat, value)
                        array.push({ FieldName: item, Value: rawDate })
                    }
                    else {
                        array.push({ FieldName: item, Value: value })
                    }


                }
            }
            detailModelArray.push(array)
        }

        return detailModelArray;
    }
    getControlDataType = (fieldName) => {
        let data = this.state.formDefinition;
        for (var i = 0; i < data.rows.length; i++) {
            let columns = data.rows[i].columns;

            for (var j = 0; j < columns.length; j++) {
                let column = columns[j];
                if (column.fieldName === fieldName) {
                    return this.setDataTypeValue(column.dataType, column.precision)
                }

                let subRows = column.subRows;
                if (subRows.length > 0 && column.rowColumnId.indexOf('grd') === -1) {
                    let dataType = this.getSubRowControlDataType(subRows, fieldName)
                    if (dataType !== '') {
                        return dataType;
                    }
                }
            }

        }
        return '';
    }
    getSubRowControlDataType = (data, fieldName) => {

        for (var i = 0; i < data.rows.length; i++) {
            let columns = data.rows[i].columns;
            for (var j = 0; j < columns.length; j++) {
                let column = columns[j];
                if (column.fieldName === fieldName) {
                    return this.setDataTypeValue(column.dataType, column.precision)
                }
            }
        }
        return '';
    }
    setDataTypeValue = (value, precision) => {

        if (value === "numbers" && precision === 0) {
            return 'int'
        }

        else if (value === "numbers" && precision > 0) {
            return 'decimal'
        }
        else if (value === "text") {
            return 'varchar'
        }
        else if (value === "Date") {
            return 'datetime'
        } else { return '' }
    }
    getFormSaveActionParameter = () => {

        isFormData = true;
        let parameterString = {
            formId: this.state.formId,
            layoutType: this.state.layoutType,
            headerData: this.setJsonModelValue(this.state.dataModel),
            gridData: this.setJsonDetailModelValue(this.state.gridDataModel),
            primaryFieldValue: this.state.primaryKeyFieldValue,
            isUpdate: this.state.isUpdate,
            primaryField: this.state.primaryKeyField,
            hasIdentity: this.state.hasIdentity,
        };
        return parameterString;

    }

    setUrlParameter = (parameter, previousParameter) => {
        let parameterQuery = "";
        if (parameter && parameter.pArguments) {

            let model = !previousParameter ? [] : !previousParameter.headerData ? [] : previousParameter.headerData
            if (model.length > 0) {
                for (var i = 0; i < parameter.pArguments.length; i++) {
                    const args = parameter.pArguments[i];
                    if (args.type === 'Dynamic') {

                        let value = model.find(field => field.FieldName === args.field).Value
                        if (value || value == 0) {
                            parameterQuery += `${args.parameter}=${encodeURIComponent(value)}&`
                        }


                    } else {
                        parameterQuery += `${args.parameter}=${encodeURIComponent(args.field)}&`

                    }

                }
                parameterQuery = `?${parameterQuery}`
            }
        }
        return parameterQuery;
    }

    setBizweaverParameter = (parameter, previousParameter) => {



        var bizweaverParam = {};
        bizweaverParam["pTaskID"] = parameter.pTaskID
        bizweaverParam["pStartAfter"] = parameter.pStartAfter
        bizweaverParam["pArguments"] = {}
        let bizweaverDynamicData = {}
        if (parameter && parameter.pArguments) {
            let model = !previousParameter ? [] : !previousParameter.headerData ? [] : previousParameter.headerData
            if (model.length > 0) {
                for (var i = 0; i < parameter.pArguments.length; i++) {
                    const args = parameter.pArguments[i];
                    if (args.parameter) {
                        if (args.type === 'Dynamic') {
                            let value = model.find(field => field.FieldName === args.field).Value
                            bizweaverDynamicData[args.parameter] = value

                        } else {
                            bizweaverDynamicData[args.parameter] = args.field;
                        }
                    }

                }
            }


        }

        bizweaverParam["pArguments"] = bizweaverDynamicData
        return bizweaverParam;


    }

    requestFileUpload = (actionStatus, action, controlId, parameters, saveSuccessCallback, history) => {
        let url = "api/FormData/Upload";
        let versagoCookie = cookies.get('Versago');

        if (versagoCookie === undefined) {
            url = "api/FormData/PublicUpload";
        }
        let uploadFiles = this.formFiles;
        if (uploadFiles.length > 0 || this.state.fileList.length > 0) {

            let primaryKeyValue = this.state.primaryKeyFieldValue
            if (!primaryKeyValue) {
                primaryKeyValue = actionStatus.createdPrimaryFieldValue;
            }
            let fileFormData = {
                formId: this.state.formId,
                layoutType: this.state.layoutType,
                primaryFieldValue: primaryKeyValue,
                isUpdate: this.state.isUpdate,
                primaryField: this.state.primaryKeyField,
                hasIdentity: this.state.hasIdentity,
                eventControlType: buttonType,
                files: [],
            };
            var data = new FormData();
            let existingFileCount = 0, newFileCount = 0;
            for (let i = 0; i < uploadFiles.length; i++) {

                for (let j = 0; j < uploadFiles[i].fileList.length; j++) {
                    const file = uploadFiles[i].fileList[j].file;
                    if (!file.fileId) {
                        data.append(`${uploadFiles[i].controlId}_${file.name}`, file.rawFile);
                        newFileCount++;
                    }
                    else {
                        existingFileCount++;
                    }
                    fileFormData.files.push({
                        controlId: uploadFiles[i].controlId,
                        fileName: file.name,
                        fileType: file.type,
                        fileSize: file.size,
                        id: !file.fileId ? 0 : file.fileId
                    })
                }
            }
            if (!(existingFileCount === this.state.fileList.length && newFileCount === 0)) {

                const jsonFileSettings = JSON.stringify(fileFormData);
                const blob = new Blob([jsonFileSettings], {
                    type: 'application/json'
                });
                data.append("fileDTO", blob);
                axios.post(url, data)
                    .then(response => {
                        if (response.status === 200) {
                            if (buttonType === Enums.ControlType.ActionWithClearGet) { //save
                                const fileList = !response.data ? [] : response.data;
                                saveSuccessCallback(actionStatus, action, controlId, parameters, fileList, history)
                            }
                            else {
                                saveSuccessCallback(actionStatus, action, controlId, parameters, [], history)
                            }

                        }
                        else {

                            this.setState({ loadingForm: false, clearFileControl: false })
                            actionCallStatus = true;
                            this.showAlert("Form file uploading failed", false);
                        }
                    }).catch(() => {

                        this.setState({ loadingForm: false, clearFileControl: false })
                        actionCallStatus = true;
                        this.showAlert("Form file uploading failed", false);
                    });
            }
            else {
                saveSuccessCallback(actionStatus, action, controlId, parameters, this.state.fileList, history)
            }

        }
        else {
            saveSuccessCallback(actionStatus, action, controlId, parameters, this.state.fileList, history)
        }


    }
    ClearFormData = (actionResponse, refreshForm, fileList, history) => {
        if (buttonType === Enums.ControlType.ActionWithClear) {
            this.resetFormData(actionResponse.responseObject[0], !actionResponse.isUpdate, actionResponse.dropDownListValue);
            // save and clear first load cancel

        }
        else if (buttonType === Enums.ControlType.ActionWithClearGet) {
            // save ..not clear update form
            if (refreshForm) {
                this.refreshFormData(actionResponse.responseObject[0], actionResponse.isUpdate, actionResponse.dropDownListValue);

            }
            else if (!actionResponse.isUpdate) {
                this.syncFormData(actionResponse.responseObject[0], true, actionResponse.dropDownListValue, fileList);
            }
            else {
                this.setUploadFileList(fileList, true, actionResponse.dropDownListValue);
            }
        }
        else if (buttonType === Enums.ControlType.ActionWithConfirmClear) {

            this.resetFormData(actionResponse.responseObject[0], !actionResponse.isUpdate, actionResponse.dropDownListValue);

        }

        else if (buttonType === Enums.ControlType.Submit) {
            //close tab.. 
           setTimeout(
                () => this.props.history? this.props.match.params.loadType === "1" ? this.closeActionLinkWindow(true) : this.props.match.params.loadType === "2" ? this.backActionLinkWindow(this.props.history): this.props.history.push('/') : this.props.history.push('/'),
                2000
            ); 
            ;
        }

        this.setState({ loadingForm: false });



    }
    refreshFormData = (response, isUpdateAction, newStaticValueList) => {
        if (isUpdateAction) {
            this.getInitialData(newStaticValueList);
        }
        else {

            for (let item in response) {

                if (response.hasOwnProperty(item)) {
                    if (item.toLowerCase() === this.state.primaryKeyField.toLowerCase()) {
                        const createdPrimaryKey = response[item];
                        this.getInitialData(newStaticValueList, createdPrimaryKey);
                        break;
                    }
                }
            }

        }
    }
    resetFormData = (response, updateStatus, newStaticValueList) => {
        let primaryKeyValue = "";
        let formData = response;

        let updateDataModel = this.state.dataModel;

        for (let item in this.defaultHeaderDataModel) {
            if (this.defaultHeaderDataModel.hasOwnProperty(item)) {
                updateDataModel[item] = this.defaultHeaderDataModel[item];
            }
        }

        for (let item in formData) {

            if (formData.hasOwnProperty(item)) {

                if (item.toLowerCase() === this.state.primaryKeyField.toLowerCase()) {
                    primaryKeyValue = formData[item];
                    updateDataModel[this.state.primaryKeyField] = primaryKeyValue;
                    break;
                }


            }

        }
        if (!primaryKeyValue) {
            primaryKeyValue = parseInt(this.state.primaryKeyFieldValue) + 1;
            updateDataModel[this.state.primaryKeyField] = primaryKeyValue;
        }
        this.formFiles = [];
        this.setState({
            dataModel: updateDataModel,
            gridDataModel: [],
            primaryKeyFieldValue: primaryKeyValue,
            isUpdate: false,
            newStaticValueList,
            validationSchema: this.clearValidationSchema(),
            fileList: [],
            totalFormFileSize: 0,
            loadingForm: false,
            clearFileControl: true,
            gridModelUpdated: false,
            retrievedDataModel: { ...updateDataModel },
        })


        //  this.setState({ loadingForm: false })


    }
    syncFormData = (response, updateStatus, newStaticValueList, fileList) => {
        let primaryKeyValue = "";
        let formData = response;

        let updateDataModel = this.state.dataModel;
        for (let item in formData) {

            if (formData.hasOwnProperty(item)) {

                if (item.toLowerCase() === this.state.primaryKeyField.toLowerCase() && updateStatus === true) {
                    primaryKeyValue = formData[item];
                    updateDataModel[this.state.primaryKeyField] = primaryKeyValue;
                }
            }

        }
        this.setUploadFileList(fileList, false);
        if (formData) {
            this.setState({ dataModel: updateDataModel, primaryKeyFieldValue: primaryKeyValue, isUpdate: updateStatus, newStaticValueList, fileList, loadingForm: false, clearFileControl: false, gridModelUpdated: false, retrievedDataModel: { ...updateDataModel }, })
        }
    }
    setUploadFileList = (fileList, setState, newStaticValueList) => {
        let totalFormFileSize = 0;
        this.formFiles = [];
        if (fileList && fileList.length > 0) {
            for (let file of fileList) {
                totalFormFileSize += parseInt(file.FileSize);
                this.setFormFiles(file.ControlId, [{
                    name: file.FileName,
                    type: file.FileType,
                    size: file.FileSize,
                    fileId: file.Id,
                }], true);
            }
        }
        else {

            fileList = [];
        }
        if (setState) {
            this.setState({ fileList, loadingForm: false, clearFileControl: false, totalFormFileSize, newStaticValueList: newStaticValueList });
        }

        return totalFormFileSize;
    }
    setFileInfoInDataModel = (dataModel) => {
        let validationSchema = this.state.validationSchema;
        for (let fileControlInfo of this.formFiles) {
            const field = validationSchema.header.find(field => field.controlId == fileControlInfo.controlId);
            if (field && fileControlInfo.fileList.length > 0) {
                dataModel[field.fieldName] = "has File";
            }
        }
        return dataModel;
    }
    //-------------------Action--------------------

    renderActionBar = (instructions) => {
        const { classes } = this.props;
        let menuCollapsed = this.props.layoutObjects.isMenuCollapsed;
        let isMobile = deviceType === Enums.DeviceType.Mobile ? true : false;
        let grid1Width = instructions ? instructions.length > 60 ? 60 : instructions.length : 0;
        const marginTOp = document.getElementById("divHeader") ? document.getElementById("divHeader").clientHeight - 15 : 0;
        let grid2Width = 100 - grid1Width;
        const actionBarChild = (

            <Grid container justify="flex-end" className={classes.instructionGrid}>
                <Grid style={{ maxWidth: `${grid1Width}%` }}>
                    <Box >
                        <Typography variant="body1" className={classes.instruction}>{instructions}</Typography>
                    </Box>
                </Grid>
                <Grid spacing={1} justifyContent="flex-end" style={{ maxWidth: `${grid2Width}%` }}>

                    {this.getButtonLayout(this.state.buttonDefinition)}

                </Grid>
            </Grid>

        )
        return (
            deviceType !== Enums.DeviceType.Mobile ? <AppBar style={{ marginTop: marginTOp, boxShadow: "none", zIndex: 2 }}> <Box pb={1} pt={2} pl={menuCollapsed ? 11 : isMobile ? 8 : 40.5} className={`${classes.buttonLayout} ${classes.buttonLayoutScroll}`} ref={(divElement) => { this.divElement = divElement }} >
                {actionBarChild} </Box></AppBar > :
                <Box pl={3} pb={3} pt={3} className={classes.buttonLayout}>{actionBarChild}</Box>
        );
    }
    
    handleSubmit = event => {

        event.preventDefault();
        this.submitButton.current.style.display = "block";
        this.submitButton.current.focus()
        this.submitButton.current.style.display = "none";
        this.callMappedAction()
        
    }

    handleActionMappedKey = event => {
       
        if (event.key === 'Enter' && this.state.actionMappedKeySettings.length > 0 && event.target.localName === 'body') {
            event.preventDefault();
            this.callMappedAction()
        }
        
    }

    callMappedAction=() => {
        const control = this.state.actionMappedKeySettings[0];
        this.callFormAction(control.action, control.controlId, control.controlType)

    }

    getFavoriteObjectDetails = () => {
        const id = parseInt(this.props.match.params.id);
        const objectId = parseInt(this.state.formId)
        let objectDetails = {
            objectType: Enums.MenuType.Form,
            objectName: '',
            objectId: 0,
            removedObjectId: 0,
            favoriteStatus: '',
        }
        if (id === objectId) {
            objectDetails.objectId = objectId
            objectDetails.objectName = this.state.formTitle
            objectDetails.favoriteStatus = this.state.favoriteStatus
        }
        else if (id > 0 && objectId == 0 && id === this.state.removedFormId) {
            objectDetails.removedObjectId = this.state.removedFormId
            objectDetails.favoriteStatus = this.state.favoriteStatus
        }
        return objectDetails;
    }
    render() {
        console.log('ProductTotal',this.state.dataModel["ProductTotal"])
        const objectDetailsForFavorite = this.getFavoriteObjectDetails();
        const formLayout = this.generateFormLayout(this.state.formDefinition.rows);
        const showHeader = (objectDetailsForFavorite.objectId || objectDetailsForFavorite.removedObjectId) ? true : false;
        const { classes } = this.props;
        const divHeight = this.divElement && deviceType !== Enums.DeviceType.Mobile ? this.divElement.clientHeight + 16 : 0;
        if (objectDetailsForFavorite.objectName) {
            setTabTitle(objectDetailsForFavorite.objectName);
        }
        return (
            <Box pt={0} className={classes.buttonContainer} >
                {showHeader && <LayoutObjectHeader title={this.state.formTitle}
                    loadType={this.props.match.params.loadType} pageAction={this.showConfirmActionLink}
                    objectDetails={objectDetailsForFavorite}
                    favoriteStatus={objectDetailsForFavorite.favoriteStatus}
                />}

                <Grid container spacing={0}>
                    <Grid item xs={12} >
                        {this.renderActionBar(this.state.instructions)}
                    </Grid>
                </Grid>

                <Box pl={3} className={classes.formContainer} style={{ marginTop: divHeight }}>
                    <BackdropLoading loading={this.state.loadingForm} />
                    <form onSubmit={this.handleSubmit}>
                        <Grid container spacing={2}>

                            {formLayout}
                            {this.state.actionMappedKeySettings.length > 0 && <Grid item>
                                <button type="submit" ref={this.submitButton} style={{ display: "none" }}>Submit</button>
                            </Grid>}
                        </Grid>
                    </form>
                </Box>
            </Box>
        );
    }
}
function mapDispatchToProps(dispatch) {
    return {
        actions: {

            alertHandler: bindActionCreators(alertHandlerAction, dispatch),
        }
    };
}
const mapStateToProps = (state) => {
    return {

        layoutObjects: state.layout,
    }
}
//export default withStyles(formTheme)(form);
export default connect(state => mapStateToProps,
    mapDispatchToProps
)(withStyles(formTheme)(form));