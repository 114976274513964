import React from 'react';
import '@syncfusion/ej2-base/styles/material.css';
import '@syncfusion/ej2-react-grids/styles/material.css';
import '@syncfusion/ej2-react-navigations/styles/material.css';
import '@syncfusion/ej2-react-inputs/styles/material.css';
import './theme/dataList.css';
import { ColumnDirective, ColumnsDirective, GridComponent, Resize, Reorder, ColumnMenu, Sort, Group, InfiniteScroll, Toolbar, ExcelExport, PdfExport, Inject, Aggregate, AggregateColumnsDirective, AggregateColumnDirective, AggregateDirective, AggregatesDirective, DetailRow, Page } from '@syncfusion/ej2-react-grids';
import { Box } from '@mui/material';
import { getDeviceType, formatValue, getEncryptDecrypt, isMobileDevice, isDesktopOrTabDevice, handleBizweaverAction } from 'client/components/Common/Utility';
import * as Enums from 'client/components/Common/Enum';
import ColumnTemplate from './ColumnTemplate';
import EditColumnTemplate from './EditColumnTemplate';
import EditCheckBoxColumnTemplate from './EditCheckBoxColumnTemplate';
import { adventProFont} from './adventProFont'
import SelectedRowSum from './SelectedRowSum';
import DataResetProxy from './DataResetProxy';
import SubDataList from './SubDataList';
import { editableGridNumericControlTheme } from './theme/dataListTheme';
import { PdfTrueTypeFont } from '@syncfusion/ej2-pdf-export';
//import { Route, withRouter } from 'react-router-dom';
import { callBizweaverAction } from 'client/api/clientActions';
import { cloneDeep } from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { alertHandlerAction } from 'client/store/AlertHandler';
import { loadingAction } from 'client/store/Loading';

let fieldCount = 0;
const deviceType = getDeviceType(); 
class dataList extends React.Component {
    constructor(props) {
        super(props);
        this.renderingMode = (deviceType === Enums.DeviceType.Mobile ? 'Vertical' : 'Horizontal');

        this.toolbarOptions = ['ExcelExport', 'CsvExport', 'PdfExport', 'Search'];
        this.modifiedRows = [...this.props.modifiedRows];
        this.customAttributes = { class: 'customcss' };
    }
    componentDidMount() {
        fieldCount = 0;
    }
    template = (column, data) => {
        
        var filterData = {
            ReportId: this.props.definition.reportId,
            FilterParameter: this.props.definition.reportFilter.filter(function (fieldData, index, arr) {
                return fieldData.value !== "" && fieldData.value != null;
            }),
            Pagination: {}
        };

        return (<ColumnTemplate data={data} column={column} dataFilter={filterData} showErrorMessage={this.props.showErrorMessage} formatValue={formatValue} actionType={false}  />);
        
        
    }
    handleLinkTemplateClick = async (actionId, data) => {
        const linkField = this.props.definition.reportLink.find(field => field.id === actionId)
        var parameter = "";
        if (linkField.actionType === Enums.LinkActionType.BizweaverWebservice) {
            linkField.reportLinkDetails.map((rLink) => {
                const pName = rLink.parameterName;
                var pValue = rLink.parameterValue;
                if (!rLink.isStatic) {
                    pValue = data[rLink.parameterValue];
                }
                parameter += (parameter.length > 0 ? "," : '') + pName + ":'" + pValue + "'"
            })
        }
        else {
            linkField.reportLinkDetails.map((rLink) => {
                const pName = rLink.parameterName;
                var pValue = rLink.parameterValue ? rLink.parameterValue : "";
                if (!rLink.isStatic) {
                    pValue = data[rLink.parameterValue] ? data[rLink.parameterValue] : "";
                }
                parameter += (parameter.length > 0 ? "&" : '') + pName + "=" + pValue
            })
        }

        switch (linkField.actionType) {
            case Enums.LinkActionType.Forms: {
                    if (parameter.length > 0) {
                    await getEncryptDecrypt(parameter).then((response) => {
                        parameter = encodeURI(response);
                    });
                }
                var Link = "/Form/";
                if (linkField.isShowInNewTab === true) {
                    Link += linkField.actionDestinationObjectId +"/1"+ (parameter.length > 0 ? "?&" + parameter : '');
                     window.open(Link, "_blank")
                } else {
                    Link += linkField.actionDestinationObjectId +"/2"+ (parameter.length > 0 ? "?&" + parameter : '');
                    // window.open(Link, "_self")  
                    this.props.pushActionLinkToHistory(Link);
                }
                break;
            }
            case Enums.LinkActionType.Report: {
                    if (parameter.length > 0) {
                    await getEncryptDecrypt(parameter).then((response) => {
                        parameter = response;
                    });
                }
                    let Link = "/Report/";
                    if (linkField.isShowInNewTab === true) { 
                        Link += linkField.actionDestinationObjectId + "/1" + (parameter.length > 0 ? "?&" + parameter : ''); 
                        window.open(Link, "_blank")
                        
                    } else {
                        Link += linkField.actionDestinationObjectId + "/2" + (parameter.length > 0 ? "?&" + parameter : '');
                        this.props.pushActionLinkToHistory(Link);
                       
                    }
                    break;
                
               
            }
            case Enums.LinkActionType.OtherURL: {
                let Link = linkField.actionDestination + (parameter.length > 0 ? "?" + parameter : '');
                try {
                    Link= new URL(Link);
                }
                catch (e)
                {
                    Link = "http://" + Link
                }
                

                if (linkField.isShowInNewTab === true) {
                    window.open(Link, "_blank")
                } else {                  
                   window.open(Link, "_self")
                }
                break;
            }
            case Enums.LinkActionType.BizweaverWebservice: {
                
                handleBizweaverAction(linkField.actionDestination, linkField.bizweaverTaskId, parameter,this.props);
                break;
            }
            case Enums.LinkActionType.CrystalReport: {
                    if (parameter.length > 0) {
                    await getEncryptDecrypt(parameter).then((response) => {
                        parameter = encodeURI(response);
                    });
                }
                var Link = "/CrystalReportViewer/";
                if (linkField.isShowInNewTab === true) {
                    Link += `${ this.props.definition.reportId}/${actionId}/1`+ (parameter.length > 0 ? "?&" + parameter : '');
                    window.open(Link, "_blank")
                } else {
                    Link += `${ this.props.definition.reportId}/${actionId}/2` + (parameter.length > 0 ? "?&" + parameter : '');
                    this.props.pushActionLinkToHistory(Link);
                }
            }
            default: { break; }
        }         
       
    }
    linktemplate = (column, data) => {
        var filterData = {
            ReportId: this.props.definition.reportId,
            FilterParameter: this.props.definition.reportFilter.filter(function (fieldData, index, arr) {
                return fieldData.value !== "" && fieldData.value != null;
            }),
            Pagination: {}
        };
        var reportOutputFields = this.props.definition.reportOutputFields.filter(r => r.fieldType === "date" || r.fieldType === "datetime");
        return (<ColumnTemplate data={data} column={column} dataFilter={filterData} reportOutputFields={reportOutputFields} handleLinkTemplateClick={this.handleLinkTemplateClick} showErrorMessage={this.props.showErrorMessage} formatValue={formatValue} actionType={true} />);
    }
    editCheckBoxTemplate = (column, data) => {
        return (<EditCheckBoxColumnTemplate setGridValue={this.setGridValue} data={data} column={column} />);
    }
    editTemplate = (column, data) => {
            return (<EditColumnTemplate
                styleSettings={editableGridNumericControlTheme}
                editableFieldSettings={this.props.definition.editableFieldSettings}
                key={data["VgoRowId"]}
                setGridValue={this.setGridValue}
                data={data}
                column={column}
                showErrorMessage={this.props.showErrorMessage}
                formatValue={formatValue} />);
    }
    
    getColumnsEdit = () => {
        const isDevice = isDesktopOrTabDevice(deviceType);
        const columns = this.props.definition.reportOutputFields.filter(field => field.isVisible).map(column => {
            const gridFieldType = {};
            if (column.gridFieldType !== Enums.GridFieldType.NotDetermined) {
                gridFieldType["type"] = column.gridFieldType;
            }
            const textAlign = isDevice ? column.textAlign : 'left';
            const LinkField = this.props.definition.reportLink.find(field => field.actionLinkField === column.fieldName)
            if (LinkField) {
                column.actionId = LinkField.id;
                column.conditionFormula = LinkField.conditionFormula;

                return (<ColumnDirective key={column.fieldId} field={column.fieldName} {...gridFieldType} showColumnMenu={column.isDisplayTitle} headerText={column.isDisplayTitle ? column.displayFieldName : ' '} width={column.columnWidth}
                    textAlign={textAlign}
                    template={this.linktemplate.bind(this, column)} ></ColumnDirective>);
            }
            else if (column.columnEditorType === "checkBox")
            {
                    return (<ColumnDirective field={column.fieldName} showColumnMenu={column.isDisplayTitle} customAttributes={this.customAttributes}
                        headerText={column.isDisplayTitle ? column.displayFieldName ? column.displayFieldName : isDevice ? '' : 'Select' : isDevice ? '' : 'Select'} width={column.columnWidth}
                        textAlign={textAlign}
                        template={this.editCheckBoxTemplate.bind(this, column)}></ColumnDirective>
                    );
            }
            else if (this.props.definition.recordProcessingDetails.editableFieldId === column.fieldName)
            {
                return (<ColumnDirective field={column.fieldName} showColumnMenu={column.isDisplayTitle} 
                    headerText={column.isDisplayTitle ? column.displayFieldName ? column.displayFieldName : ''  : ''} width={column.columnWidth}
                    textAlign={textAlign}
                    template={this.editTemplate.bind(this, column)}                    
                ></ColumnDirective>);
            }
            else if (column.fieldFormat === Enums.FormatType.None && column.kpi.length === 0) {
                if (column.fieldType === Enums.FormatType.Date || column.fieldType === Enums.FormatType.DateTime.toLowerCase() || column.fieldType === Enums.FormatType.Time) {
                    return (<ColumnDirective key={column.fieldId}
                        field={column.fieldName}
                        {...gridFieldType}
                        showColumnMenu={column.isDisplayTitle}
                        headerText={column.isDisplayTitle ? column.displayFieldName ? column.displayFieldName : isDevice ? 'Select' : '' : isDevice ? 'Select' : ''}
                        width={column.columnWidth}
                        textAlign={textAlign}
                        template={this.template.bind(this, column)}  >
                    </ColumnDirective>);
                }
                return (<ColumnDirective key={column.fieldId}
                    field={column.fieldName}
                    {...gridFieldType}
                    showColumnMenu={column.isDisplayTitle}
                    headerText={column.isDisplayTitle ? column.displayFieldName ? column.displayFieldName : '' : ''}
                    width={column.columnWidth}
                    textAlign={textAlign}
                    disableHtmlEncode={false}>
                </ColumnDirective>);



            }
            else
            {
               
                return (<ColumnDirective key={column.fieldId} field={column.fieldName} showColumnMenu={column.isDisplayTitle}
                    headerText={column.isDisplayTitle ? column.displayFieldName ? column.displayFieldName : '' : ''}
                    width={column.columnWidth}
                    textAlign={textAlign}
                    template={this.template.bind(this, column)} ></ColumnDirective>);
            }

        });
        // Adding new colum action links
        this.props.definition.reportLink.filter(field => field.actionMode === Enums.ActionMode.NewColumn).map(column => {
            //need to check if column is exixt in action if so pass action link details
            column.actionId = column.id;
            columns.push((<ColumnDirective field="" showColumnMenu={column.actionName} headerText={column.actionName} template={this.linktemplate.bind(this, column)}
            ></ColumnDirective>));

        });
            columns.unshift(
                <ColumnDirective field={"VgoRowId"} isPrimaryKey={true} visible={false} />
            );
        
        return (
            <ColumnsDirective>
                {columns}
            </ColumnsDirective>
        );
    }

    getColumns = () => {
        const isDevice = isMobileDevice(deviceType);
        const isDesktopOrTab = isDesktopOrTabDevice(deviceType);
        const columns = this.props.definition.reportOutputFields.filter(field =>field.isVisible).map(column => {
             //need to check if column is exixt in action if so pass action link details
            const gridFieldType = {};
            if (column.gridFieldType !== Enums.GridFieldType.NotDetermined) {
                gridFieldType["type"] = column.gridFieldType;
            }
            //GridFieldType
            const textAlign = isDesktopOrTab ? column.textAlign : 'left';
            const LinkField = this.props.definition.reportLink.find(field => field.actionLinkField === column.fieldName && field.actionMode === Enums.ActionMode.ExistingColumn)
            if (LinkField) {
                // conditionFormula  "DocTotal>2000"
                column.actionId = LinkField.id;
                column.conditionFormula = LinkField.conditionFormula;

                return (<ColumnDirective key={column.fieldId} field={column.fieldName} {...gridFieldType} allowSorting={isDevice ? column.isDisplayTitle ? true : false : true} showColumnMenu={column.isDisplayTitle} headerText={column.isDisplayTitle ? column.displayFieldName : ' '} width={column.columnWidth}
                    textAlign={textAlign}
                    template={this.linktemplate.bind(this, column)} ></ColumnDirective>);
            }
            else if (column.fieldFormat === Enums.FormatType.None && column.kpi.length === 0)
            {
                if (column.fieldType === Enums.FormatType.Date || column.fieldType === Enums.FormatType.DateTime.toLowerCase() || column.fieldType === Enums.FormatType.Time) {
                    return (<ColumnDirective key={column.fieldId} field={column.fieldName} {...gridFieldType} allowSorting={isDevice ? column.isDisplayTitle ? true : false : true} showColumnMenu={column.isDisplayTitle} headerText={column.isDisplayTitle ? column.displayFieldName : ' '} width={column.columnWidth}
                        textAlign={textAlign}
                        template={this.template.bind(this, column)} >                    
                    </ColumnDirective>);
                }
                return (<ColumnDirective key={column.fieldId} field={column.fieldName} {...gridFieldType} allowSorting={isDevice ? column.isDisplayTitle ? true : false : true} showColumnMenu={column.isDisplayTitle} headerText={column.isDisplayTitle ? column.displayFieldName : ' '} width={column.columnWidth}
                    textAlign={textAlign}
                    disableHtmlEncode={false}>
                </ColumnDirective>);
            }
            else
            {
                return (<ColumnDirective key={column.fieldId} field={column.fieldName} {...gridFieldType} allowSorting={isDevice ? column.isDisplayTitle ? true : false : true} showColumnMenu={column.isDisplayTitle} headerText={column.isDisplayTitle ? column.displayFieldName : ' '} width={column.columnWidth}
                    textAlign={textAlign}
                    template={this.template.bind(this, column)} ></ColumnDirective>);
            }

        });
       // Adding new colum action links
        this.props.definition.reportLink.filter(field => field.actionMode === Enums.ActionMode.NewColumn).map(column => {
            //need to check if column is exixt in action if so pass action link details
            column.actionId = column.id;
            columns.push((<ColumnDirective field={`temp${column.actionId}`} showColumnMenu={column.actionName} headerText={column.actionName} template={this.linktemplate.bind(this, column)}
            ></ColumnDirective>));
        });
        return (
            <ColumnsDirective>
                {columns}
            </ColumnsDirective>
        );
    }
  
   setSelectionOptions() {

        return this.props.definition.hasRecordSubmission ? { mode: 'Row', type: 'Multiple', enableSimpleMultiRowSelection: true } : { mode: 'Row', type: 'Single' };
        
    }
    setGroupOptions() {
        const groupOptions = this.props.definition.groupField.map(column => {
            return column.fieldName;

        });
        return { showGroupedColumn: true, columns: groupOptions }
    }
    setSortOptions(groupColumns) {
        let sortOptions = this.props.definition.sortField.map(column => {
            return { field: column.fieldName, direction: column.sortingOption };

        });

        const groupOptions = groupColumns.filter(group => !sortOptions.some(sort => sort.field === group)).map(field => {
            return { field: field, direction: 'Ascending' };

        });
        var newSortOptions = [...sortOptions, ...groupOptions];
        return { columns: newSortOptions };
    }

    setAggregates() {
        
        const aggregates = this.props.aggregateList.map(column => {
            if (column.isVisible) {
                switch (column.aggregateOption) {
                    case Enums.AggregateOptions.Sum:
                        return (
                            column.columnEditorType === "inputText" ? <AggregateColumnDirective key={column.fieldId} field={column.fieldName} type='Custom'
                                footerTemplate={this.footerCustomSum.bind(this, column)} ></AggregateColumnDirective>
                                : <AggregateColumnDirective key={column.fieldId} field={column.fieldName} type='Sum' footerTemplate={this.footerSum.bind(this, column)} ></AggregateColumnDirective>
                        );
                    case Enums.AggregateOptions.Average:
                        return (
                            <AggregateColumnDirective key={column.fieldId} field={column.fieldName} type='Average' footerTemplate={this.footerAverage.bind(this, column)} ></AggregateColumnDirective>
                        );
                    case Enums.AggregateOptions.Min:
                        return (
                            <AggregateColumnDirective key={column.fieldId} field={column.fieldName} type='Min' footerTemplate={this.footerMin.bind(this, column)} ></AggregateColumnDirective>
                        );
                    case Enums.AggregateOptions.Max:
                        return (
                            <AggregateColumnDirective key={column.fieldId} field={column.fieldName} type='Max' footerTemplate={this.footerMax.bind(this, column)} ></AggregateColumnDirective>
                        );
                    case Enums.AggregateOptions.Count:
                        return (
                            <AggregateColumnDirective key={column.fieldId} field={column.fieldName} type='Custom' customAggregate={this.customAggregateCount.bind(this, column.fieldName)} footerTemplate={this.footerCount.bind(this, column)} />
                        );

                    default:
                        return null;
                }
            }
            else
                return null;
        });

        const value = aggregates.find(obj => obj !== null);

        if (value) {
            return (
                <AggregatesDirective>
                    <AggregateDirective>
                        <AggregateColumnsDirective>
                            {aggregates}
                        </AggregateColumnsDirective>
                    </AggregateDirective>
                </AggregatesDirective>
            );
        }
        else
            return null;
    }

    footerSum(column, props) {

        let columnData = cloneDeep(column);
        if (columnData.fieldFormat === Enums.FormatType.None) {
            columnData.fieldFormat = Enums.FormatType.Decimal2
        }

        let value = "";
        if (props.Sum !== " ") {
            value = formatValue(columnData, props.Sum);
        }
        return (<span>Sum: {value}</span>);
    }
    footerAverage(column, props) {
        let value = "";
        if (props.Average !== " ") {
            value = formatValue(column, props.Average);
        }
        return (<span>Average: {value}</span>);
    }
    footerMin(column, props) {
        let value = "";
        if (props.Min !== " ") {
            if (column.fieldFormat === Enums.FormatType.Date || column.fieldFormat === Enums.FormatType.DateTime || column.fieldType === Enums.DbType.Date || column.fieldType === Enums.DbType.DateTime) {
                value = formatValue(column, props.Min.toString());
            } else {
                value = formatValue(column, props.Min);
            }

        }
        return (<span>Min: {value}</span>);
    }
    footerMax(column, props) {
        let value ="";
        if (props.Max !== " ") {
            if (column.fieldFormat === Enums.FormatType.Date || column.fieldFormat === Enums.FormatType.DateTime || column.fieldType === Enums.DbType.Date || column.fieldType === Enums.DbType.DateTime) {
                value = formatValue(column, props.Max.toString());
            } else {
                value = formatValue(column, props.Max);
            }
            
        }
        return (<span>Max: {value}</span>);
    }
    footerCount(column, props) {
        let value = props.Custom;
        return (<span>Count: {value}</span>)
    }
    footerCustomSum(column, props) {
       
        return (<SelectedRowSum column={column} />);
    }

    customAggregateCount(fieldName, args) {
        if (args.result) {
            if (fieldCount===0) {
                fieldCount = this.props.data.filter((item) => {
                    return item[fieldName] !== null
                }).length;
            }
            
            return fieldCount;
        }
        else if (args.length) {
            return args.filter((item) => {
                return item[fieldName] !== null
            }).length;
        }
        return 0;
    }
    customAggregateSelectedRowsSum(fieldName, args) {
        const selectedRowsSum = this.props.data.filter((item) => {
            return item["VgoRecordSelect"] === true
        }).reduce((a, b) => a + b[fieldName], 0);

        return selectedRowsSum;
    }

    gridCreated() {
        this.grid.localeObj.currentLocale.EmptyRecord = "Data Loading";
        const parElement = this.grid.element;
        const searchElement = parElement.querySelector('.e-search');
        if (searchElement) {
            searchElement.oninput = (event) => {
                this.grid.search(event.target.value);
            }
        }
        const emptyRecord = parElement.querySelector('.e-emptyrow');
        if (emptyRecord && this.props.loadingReportData) {

            emptyRecord.firstChild.innerText = "Data Loading"
        }
       
    }
    gridDataBound(object) {
        const isSubGrid = this.grid.detailTemplateFn;

        if (this.grid && deviceType !== Enums.DeviceType.Mobile) {
            if (isSubGrid) {
                if (!this.grid.isAutoFitColumns && this.props.data.length > 0) {
                    this.grid.autoFitColumns();
                }
            }
            else {
                this.grid.autoFitColumns();
            }
        }
        const parElement = this.grid.element;
        const emptyRecord = parElement.querySelector('.e-emptyrow');
        if (emptyRecord) {
            if (this.grid.currentViewData.length > 0) {
                emptyRecord.firstChild.innerText = "Data Loading"
            }
            else {
                if (this.props.data.length > 0) {
                    emptyRecord.firstChild.innerText = "No records to display"
                }
                else if (this.props.cancelDataLoading) {
                    emptyRecord.firstChild.innerText = "No records to display"
                }
                else if (isSubGrid && !this.props.loadingReportData) {
                    emptyRecord.firstChild.innerText = "No records to display"
                }
            }

        }

        
        
        if (this.props.definition.hasRecordSubmission && this.grid && this.props.currentStage !== "reset") {
                let selectedRowIndex = [];
                const currentView = this.grid.getCurrentViewRecords();
                for (let i = 0; i < currentView.length; i++) {
                    if (currentView[i]["VgoRecordSelect"]) {
                        selectedRowIndex.push(i);
                    }
                }
                if (selectedRowIndex.length > 0) {
                    this.grid.clearSelection();
                    this.grid.selectRows(selectedRowIndex);
                }
            }
        
    

    }
    
    resetEmptyRecordMessage = () => {
        if (this.grid ) {
            const parElement = this.grid.element;
            const emptyRecord = parElement.querySelector('.e-emptyrow');
            if (emptyRecord) {
                if (!this.props.loadingReportData && this.props.data.length === 0) {
                    emptyRecord.firstChild.innerText = "No records to display"
                }
                else if (this.props.loadingReportData) {
                    emptyRecord.firstChild.innerText = "Data Loading"
                }
                
            }
        }
    }
    
    
    toolbarClick(args) {
       
        const pdfExportProperties = {
                 fileName: `${this.props.definition.reportName}.pdf`,
                theme: {
                    caption: { font: new PdfTrueTypeFont(adventProFont, 10) },
                    header: { font: new PdfTrueTypeFont(adventProFont, 12) },
                    record: { font: new PdfTrueTypeFont(adventProFont, 9) }
                }
            };
            //if (gridInstance) {
            //    gridInstance.pdfExport(pdfExportProperties);
            //}
        
        switch (args.item.tooltipText) {
            
            case 'Excel Export':
            {
                    this.grid.excelExport({ fileName: `${this.props.definition.reportName}.xlsx` });
                break;
            }
            case 'CSV Export':
            {
                    this.grid.csvExport({ fileName: `${this.props.definition.reportName}.csv` });
                break;
            }
            case 'PDF Export':
            {
                    this.grid.pdfExport(pdfExportProperties);
                break;
            }
            default:
            {  
            }
        }
    }

    setGridValue = (newRow, type,fieldName) => {
        const primaryKeyValue = newRow["VgoRowId"];
        
        let isNewRow = true;
        
        let newDatasource = this.modifiedRows.map(row => {
            if (row["VgoRowId"] === primaryKeyValue) {
                isNewRow = false;
                row[fieldName] = newRow[fieldName];
                if (type === 'input') {
                    row["Vgo_OldValue"] = newRow["Vgo_OldValue"];
                }
                return row;
            }
            else {
                return row;
            }
        });
        if (isNewRow) {
            newDatasource.push(newRow)
          
        } 
        this.modifiedRows = newDatasource;
        this.props.setGridEditableFieldValue(newDatasource);
        
    }
    resetData = (groupSettings,sortSettings) => {
        this.modifiedRows = [];
        if(this.grid){
            this.grid.clearRowSelection();
            this.grid.clearFiltering();
            //this.grid.clearGrouping();
           // this.grid.clearSorting();
            this.grid.groupSettings = groupSettings;
            this.grid.sortSettings = sortSettings;

        }
    }
    subDataListDetailTemplate(data) {
        if (this.props.definition.hasUnAuthorizedSubReportList) {
            this.props.actions.alertHandler.showErrorMessage(`Data view loading failed`);
            return <div></div>;

        } else {
            return (
                <SubDataList parentRowData={data} subReportDefinition={this.props.definition.subReport} pushActionLinkToHistory={this.props.pushActionLinkToHistory} />
            );
        }
    }
    
    actionBegin(state) {
        
        if (state.requestType !== "refresh" && state.requestType !== "infiniteScroll") {
            const newDataList = this.props.data.map((row, index) => {

                const newRow = this.modifiedRows.filter(changeRow => changeRow["VgoRowId"] === row["VgoRowId"]);
                if (newRow.length > 0) {
                    return newRow[0]
                }
                else {
                    return row
                }

            })
           this.grid.dataSource = newDataList;
        }
       

    }
    
    
    rowSelectingEvent(checkBoxSelectionIndex, event) {
        if (!this.grid) {

        }
        else if ((event.target && event.target.type === "checkbox") || this.props.loadingReportData || !event.isInteracted ) {
            if (!event.isInteracted) {
                const parElement = this.grid.element;
                const searchElement = parElement.querySelector('.e-search');
                if (searchElement) {
                    searchElement.focus();
                }
            }
        }
        else
        {
            if (event.target && (event.target.localName !== "td") && (event.target.localName !== "p") && (event.target.localName !== "div") && (event.target.localName !== "circle")) {
                event.cancel = true;
            }
            else if (event.target && (event.target.localName === "p"))
            {
                if (event.target.parentElement && event.target.parentElement.localName === "button") {
                    event.cancel = true;
                }
                else if (event.target.parentElement && event.target.parentElement && event.target.parentElement.offsetParent.localName === "button") {
                    event.cancel = true;
                }
                else
                {
                    const checkBoxIndex = this.grid.groupSettings.columns.length + checkBoxSelectionIndex;
                    event.cancel = true;
                    if (event.row && event.row.childNodes && event.row.childNodes[1 + checkBoxIndex]
                        && event.row.childNodes[1 + checkBoxIndex].childNodes[0]
                        && event.row.childNodes[1 + checkBoxIndex].childNodes[0].childNodes[0]
                    )
                    {
                        const scrollLeft = this.grid.getContent().children[0].scrollLeft;
                        event.row.childNodes[1 + checkBoxIndex].childNodes[0].childNodes[0].click()
                        this.grid.getContent().children[0].scrollLeft = scrollLeft;
                    }
                }
                
            }
            else if (event.target && (event.target.localName === "circle")) {
                if (event.target.parentElement && event.target.parentElement && event.target.parentElement.parentElement && event.target.parentElement.parentElement.offsetParent.localName === "button") {
                    event.cancel = true;
                }
                else {
                    const checkBoxIndex = this.grid.groupSettings.columns.length + checkBoxSelectionIndex;
                    event.cancel = true;
                    if (event.row && event.row.childNodes && event.row.childNodes[1 + checkBoxIndex]
                        && event.row.childNodes[1 + checkBoxIndex].childNodes[0]
                        && event.row.childNodes[1 + checkBoxIndex].childNodes[0].childNodes[0]
                    )
                    {
                        const scrollLeft = this.grid.getContent().children[0].scrollLeft;
                        event.row.childNodes[1 + checkBoxIndex].childNodes[0].childNodes[0].click()
                        this.grid.getContent().children[0].scrollLeft = scrollLeft;
                    }
                }

            }
            else
            {
                const checkBoxIndex = this.grid.groupSettings.columns.length + checkBoxSelectionIndex;
                event.cancel = true;
                if (event.row && event.row.childNodes && event.row.childNodes[1 + checkBoxIndex]
                    && event.row.childNodes[1 + checkBoxIndex].childNodes[0]
                    && event.row.childNodes[1 + checkBoxIndex].childNodes[0].childNodes[0]
                )
                {
                    const scrollLeft = this.grid.getContent().children[0].scrollLeft;
                    event.row.childNodes[1 + checkBoxIndex].childNodes[0].childNodes[0].click()
                    this.grid.getContent().children[0].scrollLeft = scrollLeft;
                }
            }
        }
    }
    rowDeselectingEvent(checkBoxSelectionIndex,event) {
        if ((!this.grid) || (event.target && event.target.type === "checkbox") || this.props.loadingReportData || !event.isInteracted ) {

        }
        else {
            if (event.target && (event.target.localName !== "td") && (event.target.localName !== "p") && (event.target.localName !== "div") && (event.target.localName !== "circle")) {
                event.cancel = true;
            }
            else if (event.target && (event.target.localName === "p")) {
                if (event.target.parentElement && event.target.parentElement.localName === "button") {
                    event.cancel = true;
                }
                else if (event.target.parentElement && event.target.parentElement && event.target.parentElement.offsetParent.localName === "button") {
                    event.cancel = true;
                }
                else {
                    const scrollLeft = this.grid.getContent().children[0].scrollLeft;
                    const checkBoxIndex = this.grid.groupSettings.columns.length + checkBoxSelectionIndex;
                    event.cancel = true;
                    event.row.childNodes[1 + checkBoxIndex].childNodes[0].childNodes[0].click()
                    this.grid.getContent().children[0].scrollLeft = scrollLeft;
                }

            }
            else if (event.target && (event.target.localName === "circle")) {
                if (event.target.parentElement && event.target.parentElement && event.target.parentElement.parentElement && event.target.parentElement.parentElement.offsetParent.localName === "button") {
                    event.cancel = true;
                }
                else
                {
                    const scrollLeft = this.grid.getContent().children[0].scrollLeft;
                    const checkBoxIndex = this.grid.groupSettings.columns.length + checkBoxSelectionIndex;
                    event.cancel = true;
                    event.row.childNodes[1 + checkBoxIndex].childNodes[0].childNodes[0].click()
                    this.grid.getContent().children[0].scrollLeft = scrollLeft;
                }

            }
            else {
                const checkBoxIndex = this.grid.groupSettings.columns.length + checkBoxSelectionIndex;
                event.cancel = true;
                if (event.row && event.row.childNodes && event.row.childNodes[1 + checkBoxIndex]
                    && event.row.childNodes[1 + checkBoxIndex].childNodes[0]
                    && event.row.childNodes[1 + checkBoxIndex].childNodes[0].childNodes[0]
                )
                {
                    const scrollLeft = this.grid.getContent().children[0].scrollLeft;
                    event.row.childNodes[1 + checkBoxIndex].childNodes[0].childNodes[0].click()
                    this.grid.getContent().children[0].scrollLeft = scrollLeft;
                }
                
            }
            
        }
    }
    
    
    render() {
        const isDevice = isMobileDevice(deviceType);
        let columns = this.props.definition.hasRecordSubmission ? this.getColumnsEdit(): this.getColumns();
        const groupSettings = !isDevice ? this.setGroupOptions() : {};
        const sortSettings = this.setSortOptions(!isDevice ? groupSettings.columns : []);
        const aggregates = this.setAggregates();
        const selectionSettings = this.setSelectionOptions();
        const pageSettings = isDevice ? { pageCount: 4, pageSize: this.props.definition.pageRowCount} : { pageSize: this.props.definition.pageRowCount };
        const boxPadding = this.props.isFromDashboard ? { pl: 1, pr: 1 } : { pl: 1, pr: isDevice ? 2 : 7 }
        const height = (isDevice || this.props.isFromDashboard) ? "100%" : "75vh"
        const footerHeight = this.props.aggregateList.length === 0 ? 0 : 30;
        const infiniteOptions = { initialBlocks: 2 };
        
        let dashboardHeight = 90;
        
        if (this.props.isFromDashboard) {
            if (!isDevice) {
                if (this.props.panelSizeY === 1) {
                    dashboardHeight = 82;
                }
            } else {
                dashboardHeight = 75;
                if (this.props.panelSizeY === 2) {
                    dashboardHeight = 88;
                }
            }
        }
        const parentHeight = (this.props.isFromDashboard) ? `calc(${dashboardHeight}% - ${footerHeight}px)` : "100%";
        const settings = { wrapMode: 'Both' };
        const checkBoxSelectionIndex = this.props.definition.subReport ? 1 : 0;
        this.resetEmptyRecordMessage();
        let data = this.props.data || [];
        if (this.props.filterClicked && this.grid && this.grid.currentViewData.length > 0) {
            columns = <ColumnsDirective > </ColumnsDirective >
            data = [];
        }
        
        
        return (
            
            <Box height="100%" id="grdBox"  {...boxPadding} >
                <div className={isDevice ? "e-bigger" : ""} style={{ height: parentHeight }} >
                 
                    <GridComponent height={height} dataSource={data} ref={grid => this.grid = grid}
                        created={this.gridCreated.bind(this)}
                        dataBound={this.gridDataBound.bind(this)}
                        {...(this.props.definition.hasRecordSubmission ?
                        {
                            rowSelecting: this.rowSelectingEvent.bind(this, checkBoxSelectionIndex),
                            rowDeselecting: this.rowDeselectingEvent.bind(this, checkBoxSelectionIndex),
                            actionBegin: this.actionBegin.bind(this),
                        } : {})}
                        showColumnMenu={true} allowResizing={true} allowGrouping={!isDevice} allowSorting={true} allowPaging={this.props.definition.hasPaging} pageSettings={pageSettings}
                        enableInfiniteScrolling={this.props.definition.hasPaging ? false : true}
                        allowExcelExport={true} allowPdfExport={true}
                        allowReordering={true}
                        allowTextWrap={true}
                        allowKeyboard={false}
                        toolbar={this.toolbarOptions}
                        toolbarClick={this.toolbarClick.bind(this)}
                        groupSettings={groupSettings}
                        sortSettings={sortSettings}
                        selectionSettings={selectionSettings}
                        detailTemplate={(this.props.definition.subReport) ? this.subDataListDetailTemplate.bind(this) : null}
                        enableAdaptiveUI={isDevice}
                        rowRenderingMode={this.renderingMode}
                        textWrapSettings={settings}
                        resizeSettings={{ mode: 'Auto' }}
                        infiniteScrollSettings={infiniteOptions}
                        >
                    {columns}
                   {aggregates}
                        <Inject services={[Toolbar, Group, Sort, InfiniteScroll, ColumnMenu, Resize, Reorder, PdfExport, ExcelExport, Aggregate, DetailRow, Page]} />
                    </GridComponent> 
                    {this.props.definition.hasRecordSubmission && <DataResetProxy resetData={this.resetData} groupSettings={groupSettings} sortSettings={sortSettings}/>}
                </div> 
               </Box>
               
            );
    }
}
function mapDispatchToProps(dispatch) {
    return {
        actions: {
            alertHandler: bindActionCreators(alertHandlerAction, dispatch),
            loadingAction: bindActionCreators(loadingAction, dispatch),
        }
    };
}
export default connect(null,
    mapDispatchToProps
)(dataList);
 