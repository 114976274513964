import React from 'react';
import { saveAs } from 'file-saver';
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import * as enumerator from 'client/components/Common/Enum';
import { isMobile, isTablet, isIPhone13, isIPad13 } from 'react-device-detect';
import Cookies from 'universal-cookie';
import { Internationalization } from '@syncfusion/ej2-base';
import { callBizweaverAction } from 'client/api/clientActions';
const cookies = new Cookies();
const intl = new Internationalization();

export const formatNumber = (value, thousands, decimal, precision, prefix, postfix) => {
    var negative = value < 0 ? '-' : '';
    var floatValue = parseFloat(value);

    if (isNaN(floatValue)) {
        return '';
    }

    floatValue = floatValue.toFixed(precision);
    value = Math.abs(floatValue);

    var intPart = parseInt(value, 10);
    var floatPart = precision ?
        decimal + (value - intPart).toFixed(precision).substring(2) :
        '';
    var formatedValue = negative + (prefix || '') +
        intPart.toString().replace(
            /\B(?=(\d{3})+(?!\d))/g, thousands
        ) +
        floatPart +
        (postfix || '');
    return formatedValue;
};

export const formatDate = (format, dateValue) => {
    const date = moment(dateValue);
    return date.format(format);
}
export const formatDateCulture = (date, culture) => {
    var cSplit = culture.split(' ');
    var cCulture = cSplit[0].replace("mm", "MM").replace("yyyy", "YYYY").replace("hh", "HH").replace("dd", "DD");
    if (cSplit.length > 1) {
        cCulture += " " + cSplit[1].replace("hh", "HH");
    }
    if (cSplit.length > 2) {
        cCulture += " " + cSplit[2].replace("hh", "HH");
    }
    const myDate = moment(date, cCulture).toDate();
    return myDate;
}
export const formatTime = (format, timeValue) => {
    const time = moment(timeValue, 'HH:mm:ss');
    format = format.replace("a", "A")

    let a = time.format(format);
    return a;
}
export const formatExactTime = (format, timeValue) => {
    const time = moment(timeValue, format);
    const timeFormat = time.format('HH:mm');
    return timeFormat;
}
export const getCurrentDateTime = () => {
    return moment().format("DD-MM-YYYY HH:mm:ss");
}
export const getDateOnly = (date) => {
    if (date) {

        date = moment(date)
        return date.isValid() ? date.format("YYYY-MM-DDT00:00:00") : "";

    }
    else
        return date;
}
export const getCurrencyFormat = (culture, fromForm) => {
    if (fromForm) {

        let currencyFormat = {};
        currencyFormat["Local"] = [',', '.', '$'];

        currencyFormat["en-US"] = [',', '.', '$'];
        currencyFormat["en-CA"] = [',', '.', '$'];
        currencyFormat["en-AU"] = [',', '.', '$'];
        currencyFormat["en-NZ"] = [',', '.', '$'];
        currencyFormat["es-MX"] = [',', '.', '$'];

        currencyFormat["es-ES"] = ['.', ',', '€'];
        currencyFormat["pt-BR"] = ['.', ',', 'R$'];
        currencyFormat["es-AR"] = ['.', ',', '$'];
        currencyFormat["es-CL"] = ['.', ',', '$'];

        currencyFormat["es-PS"] = [',', '.', 'B/.'];
        currencyFormat["en-GB"] = [',', '.', '£'];

        currencyFormat["de-DE"] = ['.', ',', '€'];
        currencyFormat["nl-NL"] = ['.', ',', '€'];

        currencyFormat["kok-IN"] = [',', '.', '₹'];

        return currencyFormat[culture];

    }
    else {
        let currencyFormat = {};
        currencyFormat["Local"] = [',', '.', '$'];

        currencyFormat["en-US"] = [',', '.', '$'];
        currencyFormat["en-Canada"] = [',', '.', '$'];
        currencyFormat["en-Australia"] = [',', '.', '$'];
        currencyFormat["en-New Zealand"] = [',', '.', '$'];
        currencyFormat["es-Mexico"] = [',', '.', '$'];

        currencyFormat["es-Spain"] = ['.', ',', '€'];
        currencyFormat["pt-Brazil"] = ['.', ',', 'R$'];
        currencyFormat["es-Argentina"] = ['.', ',', '$'];
        currencyFormat["es-Chile"] = ['.', ',', '$'];

        currencyFormat["es-Panama"] = [',', '.', 'B/.'];
        currencyFormat["en-UK"] = [',', '.', '£'];

        currencyFormat["de-Germany"] = ['.', ',', '€'];
        currencyFormat["nl-Netherlands"] = ['.', ',', '€'];

        currencyFormat["kok-India"] = [',', '.', '₹'];

        return currencyFormat[culture];
    }

};
export const getCurrencyCode = (culture) => {
    let currencyFormat = {};
    currencyFormat["Local"] = ['USD'];
    currencyFormat["en-US"] = ['USD'];
    currencyFormat["en-CA"] = ['USD'];
    currencyFormat["en-AU"] = ['USD'];
    currencyFormat["en-NZ"] = ['USD'];
    currencyFormat["es-MX"] = ['USD'];

    currencyFormat["es-ES"] = ['USD'];
    currencyFormat["pt-BR"] = ['BRA'];
    currencyFormat["es-AR"] = ['USD'];
    currencyFormat["es-CL"] = ['USD'];

    currencyFormat["es-PS"] = ['PAB'];
    currencyFormat["en-GB"] = ['EGP'];

    currencyFormat["de-DE"] = ['EUR'];
    currencyFormat["nl-NL"] = ['EUR'];

    currencyFormat["kok-IN"] = ['INR'];

    return currencyFormat[culture];
};
export const getKpi = (value, formatedValue, kpi) => {
    for (let i = 0; i < kpi.length; i++) {
        let element = kpi[i];
        if (element.relationalOperator === 1) {
            if (parseFloat(value) < parseFloat(element.firstValue)) {
                return getKpiIndicator(element, formatedValue);

            }
        }
        else if (element.relationalOperator === 2) {
            if (parseFloat(value) > parseFloat(element.firstValue)) {
                return getKpiIndicator(element, formatedValue);

            }
        }
        else {
            let cellValue = parseFloat(value);
            if (cellValue >= parseFloat(element.firstValue) && cellValue <= parseFloat(element.secondValue)) {
                return getKpiIndicator(element, formatedValue);
            }
        }

    }
    return formatedValue;

};
const getKpiIndicator = (kpi, formatedValue) => {

    if (kpi.displayType === 1) {
        return (<span style={{ color: kpi.color }}>{formatedValue}</span>);
    }
    else if (kpi.displayType === 2) {
        return (<span style={{ backgroundColor: kpi.color }}>{formatedValue}</span>);
    } else {
        return (
            <div>
                <svg height="25" width="25"><circle cx="15" cy="15" r="10" fill={kpi.color}></circle></svg>
                <span>{formatedValue}</span>
            </div>
        );
    }
}

export const getFileDownload = async (FilePath, BinaryData, BinaryContentType, BinaryFileName) => {
    if (FilePath !== "") {

        let url = `api/ObjectData/GetFileDownload`;
        let versagoCookie = cookies.get('Versago');

        if (versagoCookie === undefined) {
            url = `api/ObjectData/GetPublicFileDownload`;
        }
        const response = await fetch(url,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    FilePath: FilePath.toString(),
                    FileName: "",
                    FileExtension: "",
                    FileData: null
                })
            });
        if (response.status === 200) {
            const fileData = await response.json();
            let blob = null
            if (fileData.fileName.toLowerCase().indexOf(".zip") !== -1) {
                const base64Response = await fetch(`data:zip;base64,${fileData.fileData}`);
                 const arrayBuffer = await base64Response.arrayBuffer();
                 blob = new Blob([arrayBuffer], { type: 'application/octet-stream' });


            }

            else {
                blob = b64toBlob(fileData.fileData, fileData.contentType, fileData.contentLength);
            }

            await saveAs(blob, fileData.fileName);
            return true;
        }
        else {
            return false;
        }
    } else if (BinaryData !== "") {
        const blob = b64toBlob(BinaryData, BinaryContentType, BinaryData.length);
        await saveAs(blob, BinaryFileName);
        return true;
    }
}
export const actionFileDownload = async (fileSettings, vgoRowId, dataFilter) => {
    if (fileSettings.fileValue === "") {
        dataFilter.FilterParameter.push({
            id: 0,
            fieldName: "VgoRowID",
            condition: "equals",
            value: vgoRowId,
            value2: null
        });

        let url = `api/ObjectData/GetRecordFileDownload`;
        let versagoCookie = cookies.get('Versago');

        if (versagoCookie === undefined) {
            url = `api/ObjectData/GetPublicRecordFileDownload`;
        }
        const response = await fetch(url,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(dataFilter)
            });
        if (response.status === 200) {
            const reportDataObject = await response.json();
            let fileTypeFieldName = 'FileType';
            let fileSizeFieldName = 'FileSize';
            if (reportDataObject.length === 0) {
                return false;
            }
            const index = 0;

            const fileType = reportDataObject[index][fileTypeFieldName];
            const fileSize = reportDataObject[index][fileSizeFieldName];
            if (fileType === undefined || fileSize === undefined) {
                let hasFileSize = false;
                let hasFileType = false;
                fileTypeFieldName = fileTypeFieldName.toLowerCase()
                fileSizeFieldName = fileSizeFieldName.toLowerCase()

                for (let property in reportDataObject[index]) {
                    if (property.toLowerCase() === fileTypeFieldName) {
                        fileTypeFieldName = property;
                        hasFileSize = true;
                    }
                    else if (property.toLowerCase() === fileSizeFieldName) {
                        fileSizeFieldName = property;
                        hasFileType = true;
                    }
                    if (hasFileSize && hasFileType) {
                        break;
                    }
                }
            }




            const blob = b64toBlob(reportDataObject[index][fileSettings.fileDataField],
                reportDataObject[index][fileTypeFieldName],
                reportDataObject[index][fileSizeFieldName]);
            await saveAs(blob, reportDataObject[index][fileSettings.fileNameField]);
            return true;

        } else {
            return false;
        }
    } else {
        return await getFileDownload(fileSettings.fileValue);
    }
}
export const b64toBlob = (b64Data, contentType, sliceSize) => {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;
    var byteCharacters = atob(b64Data);
    var byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);
        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
}
export const getDeviceType = () => {

    if (isMobile) {
        if (isTablet) {
            return enumerator.DeviceType.Tablet;
        }
        else {
            return enumerator.DeviceType.Mobile;
        }
    }
    else {
        if (isIPad13) {
            return enumerator.DeviceType.Tablet;
        }
        else if (isIPhone13) {
            return enumerator.DeviceType.Mobile;
        } else {
            return enumerator.DeviceType.Desktop;
        }
    }


}
export const isMobileDevice = (deviceType) => {
    return deviceType === enumerator.DeviceType.Mobile ? true : false;
}
export const isDesktopOrTabDevice = (deviceType) => {
    return deviceType != enumerator.DeviceType.Mobile ? true : false;
}
export const changeTimeZoneFromUTC = (fromZone, toZone, dateValue, addMinutes) => {
        
    const serverDate = addMinutes ? moment(dateValue).add(30, 'minutes') : moment(dateValue);   
    var fromDateZone = serverDate.clone().tz(fromZone, true); 
    var toDateZone = fromDateZone.clone().tz(toZone);
    const convertedDate = moment(toDateZone.format('YYYY-MM-DDTHH:mm:ss')).toDate()
    return convertedDate;

}
export const changeTimeZone = (fromZone, toZone, dateValue, addMinutes) => {
    const serverDate = addMinutes ? moment(dateValue.toString()).add(30, 'minutes') : moment(dateValue.toString());
    var fromDateZone = serverDate.clone().tz(fromZone, true);
    var toDateZone = fromDateZone.clone().tz(toZone);
    return toDateZone.toDate();

}
export const getDefaultClientTimeZone = () => {
    return moment.tz.guess();
}
export const getTimeZoneList = () => {
    return moment.tz.names();
}
export const printReport = () => {
    let chartContent = "";
    let chartCanvasList = document.getElementsByTagName("canvas");
    if (chartCanvasList.length > 0) {
        let funnelChartTitle = document.getElementById("funnelChartTitle") === null ? "" : document.getElementById("funnelChartTitle").innerHTML;
        let chartCanvas = chartCanvasList[0].toDataURL();
        chartContent = `<div class="box box-chart"><div class="box-body"><div style="text-align:center">${funnelChartTitle} <img src="${chartCanvas}" /></div></div></div>`
    }

    let printContents = document.getElementById("gridData").innerHTML;
    let styleList = document.getElementsByTagName("STYLE");
    let styleCss = '';
    for (let i = 0; i < styleList.length; i++) {
        styleCss += `<style type="text/css">${styleList[i].innerHTML}</style>`;
    }
    var popupWin = window.open('', '_blank');
    popupWin.document.open();
    popupWin.document.write(`<html><head> ${styleCss} </head><body><div>${chartContent}  ${printContents}</div></body></html>`);
    popupWin.document.close();



}
export const getReportExportHtml = (reportName) => {
    let chartContent = "";
    let chartCanvasList = document.getElementsByTagName("canvas");
    if (chartCanvasList.length > 0) {
        let funnelChartTitle = document.getElementById("funnelChartTitle") === null ? "" : document.getElementById("funnelChartTitle").innerHTML;
        let chartCanvas = chartCanvasList[0].toDataURL();
        chartContent = `<div class="box box-chart"><div class="box-body"><div style="text-align:center">${funnelChartTitle} </div><img src="${chartCanvas}" /></div></div>`
    }

    let styleList = document.getElementsByTagName("STYLE");
    let styleCss = '';
    for (let i = 0; i < styleList.length; i++) {
        styleCss += `<style type="text/css">${styleList[i].innerHTML}</style>`;
    }
    const html = `<div style="text-align:center"><h1 class='title'><span> ${reportName}</span></h1></div> ${chartContent} ${document.getElementById("gridData").innerHTML}`;
    return { html: html, style: styleCss }
}

export const getURLParameter = (reportFilters, actionAdded) => {
    let search = window.location.search;
    let parameters = new URLSearchParams(search);
    let actionType = parameters.get('ActionType');
    let actionParameterObject = { hasAction: false, parameterObject: {} }
    if (actionAdded === false && actionType != null) {
        const parameterList = search.slice(1).split(/&|=/);
        for (let i = 0; i < parameterList.length; i += 2) {
            if (i !== 0)
                reportFilters.push({
                    id: 0,
                    fieldName: parameterList[i],
                    condition: 'equals',
                    value: parameterList[i + 1],
                    value2: null,
                    Type: 'Link'
                });


        }
        actionParameterObject = { hasAction: true, parameterObjectList: reportFilters, actionType }
    }
    return actionParameterObject;
}

export const setSelectedMenuItem = (event, setItem) => {
    var elements = document.getElementsByClassName('nav-selectedItem');
    if (elements.length > 0) {

        elements[0].classList.remove('nav-selectedItem');
    }
    if (setItem) {
        event.currentTarget.classList.add('nav-selectedItem');
    }


}


export const addDate = (date, number, type) => {
    return moment(date).add(number, type).toDate();
}
export const dateDifference = (startDate, endDate, type) => {
    const startDateMoment = moment(startDate);
    const endDateMoment = moment(endDate);
    return startDateMoment.diff(endDateMoment, type);
}

export const getNumericSettings = (control, value) => {
    let numericSettings = { Id: control.controlId };
    numericSettings["name"] = control.fieldName;
    numericSettings["caption"] = control.caption;
    numericSettings["textAlign"] = control.textAlign;
    numericSettings["hasFocus"] = control.hasFocus;
    // numericSettings["value"] = value;
    if (control.format === enumerator.FormatType.Currency) {
        let culture = !control.culture ? "en-US" : control.culture;
        let numberFormat = getCurrencyFormat(culture, true);
        //if (control.precision === 0) {
        //    value = !value ? '' : parseInt(value);
        //} else {
        //    value = !value ? '' : parseFloat(value);
        //}
        numericSettings["thousandSeparator"] = numberFormat[0];
        numericSettings["decimalSeparator"] = numberFormat[1];
        numericSettings["prefix"] = numberFormat[2];

    }
    else if (control.format === enumerator.FormatType.Decimal) {
        let culture = !control.culture ? "en-US" : control.culture;
        let numberFormat = getCurrencyFormat(culture, true);
        //value = !value ? '' : parseFloat(value);
        if (numberFormat) {
            numericSettings["thousandSeparator"] = numberFormat[0];
            numericSettings["decimalSeparator"] = numberFormat[1];
        }
        numericSettings["prefix"] = '';

    }
    else {
        numericSettings["thousandSeparator"] = false;
        numericSettings["decimalSeparator"] = ".";
    }
    if (control.precision >= 0) {
        numericSettings["decimalScale"] = control.precision;
        numericSettings["fixedDecimalScale"] = true;
    }

    return numericSettings;
}

const setCulture = (value) => {
    return value.replace(',', '/').replace('.', ',').replace('/', '.');
}

const setCurrencyCode = (culture) => {
    let code;
    switch (culture) {
        case 'en-Canada':
        case 'en-New Zealand':
        case 'en-Australia':
        case 'es-Argentina':
        case 'es-Chile':
        case 'es-Mexico':
            code = 'USD';
            break;

        case 'es-Panama':
            code = 'PAB';
            break;

        case 'pt-Brazil':
            code = 'BRL';
            break;

        case 'en-UK':
            code = 'GBP';
            break;

        case 'kok-India':
            code = 'INR';
            break;

        case 'de-Germany':
        case 'es-Spain':
        case 'nl-Netherlands':
            code = 'EUR';
            break;

        default:
            code = 'USD';
            break;

    }
    return code;
}

const setSymbols = (value, code) => {
    let newValue;
    switch (code) {
        case 'EUR':
            newValue = setCulture(value);
            break;

        case 'INR':
            newValue = value.replace('$', '₹');
            break;

        case 'BRL':
            newValue = setCulture(value.replace('$', 'R$'));
            break;

        case 'PAB':
            newValue = value.replace('$', 'B/.');
            break;

        default:
            newValue = value;
            break;


    }
    return newValue;
}
export const formFormatValue = (column, value) => {
    let format;


    if (!(!value) || value === 0) {

        if (column.fieldFormat == enumerator.FormatType.Currency) {
            column.culture = (!column.culture) ? "en-US" : column.culture;


            switch (column.precision) {

                case 1:
                    {
                        const code = getCurrencyCode(column.culture);
                        format = intl.formatNumber(value, {
                            format: 'C1', currency: code[0]

                        });
                        break;
                    }
                case 2:
                    {
                        const code = getCurrencyCode(column.culture);
                        format = intl.formatNumber(value, {
                            format: 'C2', currency: code[0]

                        });
                        break;
                    }
                case 3:
                    {
                        const code = getCurrencyCode(column.culture);
                        format = intl.formatNumber(value, {
                            format: 'C3', currency: code[0]

                        });
                        break;
                    }
                case 4:
                    {
                        const code = getCurrencyCode(column.culture);
                        format = intl.formatNumber(value, {
                            format: 'C4', currency: code[0]

                        });
                        break;
                    }
                default:
                    {
                        const code = getCurrencyCode(column.culture);
                        format = intl.formatNumber(value, {
                            format: 'C0', currency: code[0]

                        });
                        break;
                    }

            }

        }
        else if (column.fieldFormat === enumerator.FormatType.Date) {
            format = intl.formatDate(new Date(value), { format: column.culture });
        }
        else if (column.fieldFormat === enumerator.FormatType.DateTime) {
            const hourFormat = column.culture + (column.hourFormat ? " a" : "") || '';
            format = intl.formatDate(new Date(value), { format: hourFormat });
        }
        else if (column.fieldFormat === enumerator.FormatType.Time) {
            const hourFormat = column.hourFormat ? 'hh:mm a' : 'HH:mm';
            const newDateValue = new Date(value);
            if (newDateValue.toString() !== 'Invalid Date') {
                format = intl.formatDate(newDateValue, { format: hourFormat }).toLowerCase();
            }
            else {
                const result = moment(value, hourFormat).toDate();
                format = intl.formatDate(new Date(result), { format: hourFormat }).toLowerCase();
            }
            
            
        }
        else {
            if (column.controlType === enumerator.ControlType.DropdownList || column.controlType === enumerator.ControlType.TextBox) {
                return value;
            }
            switch (column.precision) {

                case 1:
                    {
                        const newValue = intl.formatNumber(value, {
                            format: 'N1'
                        });
                        format = newValue;
                        break;
                    }
                case 2:
                    {
                        const newValue = intl.formatNumber(value, {
                            format: 'N2'
                        });
                        format = newValue;
                        break;
                    }
                case 3:
                    {
                        const newValue = intl.formatNumber(value, {
                            format: 'N3'
                        });
                        format = newValue;
                        break;
                    }
                case 4:
                    {
                        const newValue = intl.formatNumber(value, {
                            format: 'N4'
                        });
                        format = newValue;
                        break;
                    }

                default:
                    {
                        format = value;
                        break;
                    }
            }
        }
        if (column.culture && column.dataType === "numbers") {
            const code = getCurrencyCode(column.culture) || '';
            format = setSymbols(format, code[0]);
            format = (column.culture === 'es-CL' || column.culture === 'es-AR')
                ? setCulture(format)
                : format;
        }
    }


    return format;
}
export const formatValue = (column, value) => {
    let format;
    if (value || value === 0) {
        switch (column.fieldFormat) {
            
            case enumerator.FormatType.Decimal1:
                {
                    const newValue = intl.formatNumber(value, {
                        format: 'N1'
                    });
                    format = (column.culture === '1.000,0') ? setCulture(newValue) : newValue;
                    break;
                }
            case enumerator.FormatType.Decimal2:
                {
                    const newValue = intl.formatNumber(value, {
                        format: 'N2'
                    });
                    format = (column.culture === '1.000,00') ? setCulture(newValue) : newValue;
                    break;
                }
            case enumerator.FormatType.Decimal3:
                {
                    const newValue = intl.formatNumber(value, {
                        format: 'N3'
                    });
                    format = (column.culture === '1.000,000') ? setCulture(newValue) : newValue;
                    break;
                }
            case enumerator.FormatType.Decimal4:
                {
                    const newValue = intl.formatNumber(value, {
                        format: 'N4'
                    });
                    format = (column.culture === '1.000,0000') ? setCulture(newValue) : newValue;
                    break;
                }
            case enumerator.FormatType.Currency:
                {
                    const code = setCurrencyCode(column.culture);
                    const newValue = intl.formatNumber(value, {
                        format: 'C2', currency: code

                    });
                    format = setSymbols(newValue, code);
                    format = (column.culture === 'es-Chile' || column.culture === 'es-Argentina')
                        ? setCulture(format)
                        : format;
                    break;
                }
            case enumerator.FormatType.Date:
            case enumerator.FormatType.DateTime:
            case enumerator.FormatType.Time:
                {
                    if (column.fieldType === enumerator.FormatType.Time) {

                        format = formatTime(column.culture, value);
                    } else {
                        format = intl.formatDate(new Date(value), { format: column.culture });
                    }
                    break;
                }
            default:
                {
                    
                    //set defult date format
                    if (column.fieldType === enumerator.FormatType.Time) {
                        format = formatTime(column.culture, value);
                    } else if (column.fieldType === enumerator.FormatType.Date || column.fieldType === enumerator.FormatType.DateTime.toLowerCase()) {
                        format = intl.formatDate(new Date(value), { format: column.culture });
                    } else {
                        format = value;
                    }
                    break;
                }
        }
    }
    return format;
}

export const getNumericFieldFormatSettings = (field) => {
    const settings = {};

    switch (field.fieldFormat) {
        case enumerator.FormatType.Decimal:
            {
                settings.decimalScale = 2
                break;
            }
        case enumerator.FormatType.Decimal1:
            {
                settings.decimalScale = 1
                if (field.culture === '1.000,0') {
                    settings.thousandSeparator = "."
                    settings.decimalSeparator = ","
                    settings["fixedDecimalScale"] = true;
                }
                else {
                    settings.thousandSeparator = ","
                    settings.decimalSeparator = "."
                    settings["fixedDecimalScale"] = true;
                }
                break;
            }
        case enumerator.FormatType.Decimal2:
            {
                settings.decimalScale = 2
                if (field.culture === '1.000,00') {
                    settings.thousandSeparator = "."
                    settings.decimalSeparator = ","
                    settings["fixedDecimalScale"] = true;
                }
                else {
                    settings.thousandSeparator = ","
                    settings.decimalSeparator = "."
                    settings["fixedDecimalScale"] = true;
                }
                break;
            }
        case enumerator.FormatType.Decimal3:
            {
                settings.decimalScale = 3
                if (field.culture === '1.000,000') {
                    settings.thousandSeparator = "."
                    settings.decimalSeparator = ","
                    settings["fixedDecimalScale"] = true;
                }
                else {
                    settings.thousandSeparator = ","
                    settings.decimalSeparator = "."
                    settings["fixedDecimalScale"] = true;
                }
                break;
            }
        case enumerator.FormatType.Decimal4:
            {
                settings.decimalScale = 4
                if (field.culture === '1.000,0000') {
                    settings.thousandSeparator = "."
                    settings.decimalSeparator = ","
                    settings["fixedDecimalScale"] = true;
                }
                else {
                    settings.thousandSeparator = ","
                    settings.decimalSeparator = "."
                    settings["fixedDecimalScale"] = true;
                }
                break;
            }
        case enumerator.FormatType.Currency:
            {
                settings.decimalScale = 2
                let culture = !field.culture ? "en-US" : field.culture;

                let numberFormat = getCurrencyFormat(culture.includes("Local") ? "Local" : culture);
                settings["thousandSeparator"] = numberFormat[0];
                settings["decimalSeparator"] = numberFormat[1];
                settings["prefix"] = numberFormat[2];
                settings["fixedDecimalScale"] = false;

                break;
            }
        default:
            {
                settings["thousandSeparator"] = '';
                settings["decimalSeparator"] = ".";
                settings["prefix"] = '';
                settings["fixedDecimalScale"] = false;
                break;
            }
    }
    return settings;
}
export const convertAllRuleFormula = (stringFormula) => {
    // The rule fails when the 'AND' or 'OR' operator is not inserted between the close [)] and open [(] brackets.
    let subString = stringFormula.split(')');
    if (subString[1] && subString[1].trim() !== "") {
        subString = subString.slice(1)
            .join('')
            .split('(')
            .slice(0, -1)
            .join('');
        if ((!subString.includes("AND")) && (!subString.includes("OR"))) {
            return ')';
        }
    }
    const regxAnd = new RegExp("\\bAND\\b");
    const regxOr = new RegExp("\\bOR\\b");
    //let newStringFormula = stringFormula.replaceAll(regxAnd, "&&");//.replaceAll(regxOr, "||");
    //newStringFormula = stringFormula.replaceAll(regxOr, "||");
    let newStringFormula = stringFormula.split(regxAnd).join("&&");
    newStringFormula = newStringFormula.split(regxOr).join("||");
    if (newStringFormula.includes("between")) {
        if (newStringFormula.includes("&&")) {
            const formulaList = newStringFormula.split("&&");
            let newFormula = formulaList.map(formula => {
                if (formula.includes("between")) {
                    formula = convertBetweenComparison(formula);
                }
                return formula;
            });
            newStringFormula = newFormula.join(' && ');

        }
        else if (newStringFormula.includes("||")) {
            const formulaList = newStringFormula.split("||");
            let newFormula = formulaList.map(formula => {
                if (formula.includes("between")) {
                    formula = convertBetweenComparison(formula);
                }
                return formula;
            });
            newStringFormula = newFormula.join(' || ');

        }
        else {

            if (newStringFormula.includes("between")) {
                if (newStringFormula.includes(")")) {

                    const formulaList = newStringFormula.split(')');
                    let newFormula = formulaList.map(formula => {
                        if (formula.trim()) {
                            formula += ' ) ';
                            if (formula.includes("between")) {
                                formula = convertBetweenComparison(formula);
                            }
                            return formula;
                        }
                    });

                    newStringFormula = newFormula.join('');
                }
            }
        }
    }

    let fieldNameSpace = newStringFormula.match(/(?<=\[)[^\][]*(?=])/g);
    if (fieldNameSpace) {
        for (const fieldName of fieldNameSpace) {
            const newField = fieldName.replace(/\s/g, '')
            newStringFormula = newStringFormula.replace(fieldName, newField);
        }
    }

    return newStringFormula;
}
const convertBetweenComparison = (formula) => {


    const arr = formula.split("between");
    const openBracket = arr[0].indexOf('(');
    const leftSide = arr[0].slice(openBracket + 1, arr[0].length)

    const closeBracket = arr[1].indexOf(')');
    const rightSide = arr[1].slice(0, closeBracket)

    const values = rightSide.split("and");

    formula = `${leftSide} >= ${values[0]} && ${leftSide} <= ${values[1]}`
    formula = (openBracket !== -1) ? `( ${formula}` : formula;
    formula = (closeBracket !== -1) ? `${formula} )` : formula;

    return formula;
}
export const getEncryptDecrypt = async (value) => {

    const url = 'api/ReportDefinition';
    return await fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(value) }).then((response) => {
        return response.json().then((data) => {
            console.log(data);
            return data.responseObject;

        }).catch((err) => {
            console.log(err);
        })
    });

}
export const checkIfObjectAreEqual = (object1, object2) => {
    for (var prop in object2) {
        var Obj1Value = object1[prop] == null ? "" : object1[prop]
        var Obj2Value = object2[prop] == null ? "" : object2[prop]
        if (Obj1Value !== Obj2Value)
            return false
    };
    return true;
}
export const checkIfObjectArrayAreEqual = (Array1, Array2) => {
    if (Array1.length > 0) {
        for (let i = 0; i < Array1.length; i++) {
            if (!checkIfObjectAreEqual(Array1[i], Array2[i])) {
                return false;
            }
        }
    }
    return true;
}

export const setTabTitle = (title, isStatic) => {
    document.title = isStatic ? title :`Versago - ${title}`;
}

export const sortName = (nameA, nameB) => {

    const nameAUpper = nameA.toUpperCase();
    const nameBUpper = nameB.toUpperCase();
    if (nameAUpper < nameBUpper) {
        return -1;
    }
    if (nameAUpper > nameBUpper) {
        return 1;
    }

    return 0;
}

export const handleBizweaverAction = async (workFlowurl, taskId, parameters,props) => {
    props.loadingAction ? props.loadingAction(true): props.actions.loadingAction.setLoading(true)
    await callBizweaverAction(workFlowurl, taskId, parameters)
        .then(response => {
            props.loadingAction ? props.loadingAction(false) : props.actions.loadingAction.setLoading(false)
            if (response.status === 200) {
                props.handleAlertHandlerMessage ? props.handleAlertHandlerMessage(true, enumerator.BizWeaverMessage.Success) : props.alertHandler ? props.alertHandler.showSuccessMessage(enumerator.BizWeaverMessage.Success) : props.actions.alertHandler.showSuccessMessage(enumerator.BizWeaverMessage.Success)
            }
            else {
                props.handleAlertHandlerMessage ? props.handleAlertHandlerMessage(false, enumerator.BizWeaverMessage.Error) : props.alertHandler ? props.alertHandler.showErrorMessage(enumerator.BizWeaverMessage.Error) : props.actions.alertHandler.showErrorMessage(enumerator.BizWeaverMessage.Error)


            }
        }).catch((err) => {
            props.loadingAction ? props.loadingAction(false) : props.actions.loadingAction.setLoading(false)
            props.handleAlertHandlerMessage ? props.handleAlertHandlerMessage(false, enumerator.BizWeaverMessage.Error) : props.alertHandler ? props.alertHandler.showErrorMessage(enumerator.BizWeaverMessage.Error) : props.actions.alertHandler.showErrorMessage(enumerator.BizWeaverMessage.Error)

        });

}
