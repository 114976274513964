import * as React from 'react';
import { Box } from '@mui/material';
import { ScheduleComponent, ViewsDirective, ViewDirective, Day, Week, WorkWeek, Month, Year, Agenda, Inject } from '@syncfusion/ej2-react-schedule';

import { Internationalization } from '@syncfusion/ej2-base';
import { changeTimeZone, changeTimeZoneFromUTC, getDeviceType} from 'client/components/Common/Utility';
import * as enumerators from 'client/components/Common/Enum';
import { formatValue } from 'client/components/Common/Utility';
import './theme/schedule.css';
//import SchedulerCustomToolBar from './SchedulerCustomToolBar'
import SchedulerFooter from './SchedulerFooter'
import { scheduleTheme } from './theme/scheduleTheme';
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-calendars/styles/material.css";
import "@syncfusion/ej2-dropdowns/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-lists/styles/material.css";
import "@syncfusion/ej2-navigations/styles/material.css";
import "@syncfusion/ej2-popups/styles/material.css";
import "@syncfusion/ej2-splitbuttons/styles/material.css";
import "@syncfusion/ej2-react-schedule/styles/material.css";
import * as Enums from 'client/components/Common/Enum';
import { withStyles } from '@mui/styles';
import * as moment from 'moment';
const deviceType = getDeviceType();
var OpenMore = false;
class Schedule extends React.Component {
    constructor(props) {
        super(props);
        this.instance = new Internationalization();
        this.state = {
            eventLoading: false,
            eventData: [],
            showEventsDialog: false,
            seletedEventId: -1
        }
    }

    componentDidMount() {

        this.setEventDate(this.props.schedulerdata);
    }
    componentDidUpdate(nextProps) {
        if (this.props.componentKey !== nextProps.componentKey) {

            this.setEventDate(this.props.schedulerdata);
        }
    }
    setOpenMore = (open) => {
        OpenMore = open;
    }
    getTitleAndFieldByIndex = (index, row) => {
        const titleField = this.props.definition.calendar.titleFields[index];
        

        let eventLabel = !titleField.title ? '' : `${titleField.title} `;
        if (titleField.fieldName) {
            if (eventLabel) {
                eventLabel = `${eventLabel} `;
            }
            let displayValue = row[titleField.fieldName];
            if (titleField.fieldName === this.props.definition.calendar.startTime.fieldName || titleField.fieldName === this.props.definition.calendar.endTime.fieldName || titleField.fieldName === this.props.definition.calendar.startDate.fieldName || titleField.fieldName === this.props.definition.calendar.endDate.fieldName) {
                displayValue=row["tz" + titleField.fieldName]
            }  
            if (displayValue === null || displayValue === undefined) {
                displayValue = "";
            }
            if (titleField.fieldFormat !== enumerators.FormatType.None) {
                let column = this.props.definition.reportOutputFields.filter(field => field.fieldName === titleField.fieldName)[0];
                if (titleField.fieldFormat === enumerators.FormatType.Date || titleField.fieldFormat === enumerators.FormatType.DateTime) {
                    displayValue = formatValue(column, displayValue.toString());
                } else {
                    displayValue = formatValue(column, displayValue);
                }
            }
            eventLabel = `${eventLabel} ${displayValue}`;

        }
        return eventLabel;
    }
    setEventDate = (dataSet) => {
        this.setState({ eventLoading: true });
        const calendarDefinition = this.props.definition.calendar;
        let clientTimeZone = this.props.timezone
        const serverTimeZone = calendarDefinition.serverTimeZone;
        const startDateFieldName = "VGO_StartTime" + calendarDefinition.startDate.fieldName;
        const endDateFieldName = "VGO_EndTime" + calendarDefinition.endDate.fieldName;
        const dateFormat = "YYYY-MM-DD";
        const timeFormat = "HH:mm:ss";
        let eventDataSet;
        if (calendarDefinition.eventFieldSettings === enumerators.CalendarEventFieldSettings.HasStartDate) {
            eventDataSet = dataSet.filter(event => event[calendarDefinition.startDate.fieldName]).map((row) => {

                const eventStartDate = row[calendarDefinition.startDate.fieldName];
                const changedEventStart = !eventStartDate ? '' : changeTimeZone(serverTimeZone, clientTimeZone, eventStartDate);
                row[startDateFieldName] = changedEventStart;
                const changedEventEnd = !eventStartDate ? '' : changeTimeZone(serverTimeZone, clientTimeZone, eventStartDate,true);
                row[endDateFieldName] = changedEventEnd;
                row["Subject"] = this.getTitleAndFieldByIndex(0, row);
                row["tz" + calendarDefinition.startDate.fieldName] = changedEventStart;
                row["tz" + calendarDefinition.endDate.fieldName] = changedEventEnd;
                 
                return (
                    row
                );
            });
        }
        else if (calendarDefinition.eventFieldSettings === enumerators.CalendarEventFieldSettings.HasStartDateTime) {
            eventDataSet = dataSet.filter(event => event[calendarDefinition.startDate.fieldName]).map((row) => {

                const eventStartDate = !row[calendarDefinition.startDate.fieldName] ? '' :row[calendarDefinition.startDate.fieldName].toISOString();
                const eventStartTime = calendarDefinition.startTime.fieldType === enumerators.FormatType.Time ? row[calendarDefinition.startTime.fieldName] : row[calendarDefinition.startTime.fieldName].toISOString();
                if (!eventStartDate) {
                    return row;
                }
                const startDate = eventStartDate.split('T');
                if (!eventStartTime) {
                    const changedEventStart = changeTimeZoneFromUTC(serverTimeZone, clientTimeZone, (startDate[0] + 'T00:00:00'));
                    row[startDateFieldName] = changedEventStart;
                    row["tz" + calendarDefinition.startDate.fieldName] = changedEventStart;
                    const changedEventEnd = changeTimeZoneFromUTC(serverTimeZone, clientTimeZone, (startDate[0] + 'T00:00:00'),true);
                    row[endDateFieldName] = changedEventEnd;
                    row["tz" + calendarDefinition.endDate.fieldName] = changedEventEnd;
                    

                }
                else {
                    const startTime = eventStartTime.split('T');
                    const time = startTime.length === 1 ? startTime[0] : startTime[1];
                    const changedEventStart = changeTimeZoneFromUTC(serverTimeZone, clientTimeZone, (startDate[0] + 'T' + time));
                    row[startDateFieldName] = changedEventStart;
                    row["tz" + calendarDefinition.startDate.fieldName] = moment(changedEventStart).format(dateFormat);
                    row["tz" + calendarDefinition.startTime.fieldName] = moment(changedEventStart).format(timeFormat);
                    const changedEventEnd = changeTimeZoneFromUTC(serverTimeZone, clientTimeZone, (startDate[0] + 'T' + time),true);
                    row[endDateFieldName] = changedEventEnd;
                    row["tz" + calendarDefinition.endDate.fieldName] = changedEventEnd;

                }
                row["Subject"] = this.getTitleAndFieldByIndex(0, row);

                return (
                    row
                );
            });
        }
        else if (calendarDefinition.eventFieldSettings === enumerators.CalendarEventFieldSettings.HasStartDateAndEndDate) {
            eventDataSet = dataSet.filter(event => event[calendarDefinition.startDate.fieldName]).map((row) => {

                const eventStartDate = row[calendarDefinition.startDate.fieldName];
                const eventEndDate = row[calendarDefinition.endDate.fieldName];

                const changedEventStart = !eventStartDate ? '' : changeTimeZone(serverTimeZone, clientTimeZone, eventStartDate);
                const changedEventEnd = !eventEndDate ? '' : changeTimeZone(serverTimeZone, clientTimeZone, eventEndDate);
                row[startDateFieldName] = changedEventStart;
                row["tz" + calendarDefinition.startDate.fieldName] = changedEventStart;

                row[endDateFieldName] = changedEventEnd;
                row["tz" + calendarDefinition.endDate.fieldName] = changedEventEnd;
                row["Subject"] = this.getTitleAndFieldByIndex(0, row);
                return (
                    row
                );
            });

        }
        else {
            if (calendarDefinition.startDate.fieldName === calendarDefinition.startTime.fieldName && calendarDefinition.endDate.fieldName === calendarDefinition.endTime.fieldName) {
                eventDataSet = dataSet.filter(event => event[calendarDefinition.startDate.fieldName]).map((row) => {

                    const eventStartDate = row[calendarDefinition.startDate.fieldName];
                    const eventEndDate = row[calendarDefinition.endDate.fieldName];

                    const changedEventStart = !eventStartDate ? '' : changeTimeZone(serverTimeZone, clientTimeZone, eventStartDate);
                    const changedEventEnd = !eventEndDate ? '' : changeTimeZone(serverTimeZone, clientTimeZone, eventEndDate);
                    row[startDateFieldName] = changedEventStart;
                    row["tz" + calendarDefinition.startDate.fieldName] = changedEventStart;
 
                    row[calendarDefinition.startTime.fieldName] = moment(changedEventStart).format(timeFormat);

                    row[endDateFieldName] = changedEventEnd;
                    row["tz" + calendarDefinition.EndDate.fieldName] = changedEventEnd;
                    row["Subject"] = this.getTitleAndFieldByIndex(0, row);

                    return (
                        row
                    );
                });
            }
            else {
                eventDataSet = dataSet.filter(event => event[calendarDefinition.startDate.fieldName]).map((row) => {
                    
                    const eventStartDate = !row[calendarDefinition.startDate.fieldName] ? '' : row[calendarDefinition.startDate.fieldName].toISOString();
                    const eventEndDate = !row[calendarDefinition.endDate.fieldName] ? '' : row[calendarDefinition.endDate.fieldName].toISOString();

                    const eventStartTime = !row[calendarDefinition.startTime.fieldName] ? '' : (calendarDefinition.startTime.fieldType === enumerators.FormatType.Time ? row[calendarDefinition.startTime.fieldName] : row[calendarDefinition.startTime.fieldName].toISOString());
                    const eventEndTime = !row[calendarDefinition.endTime.fieldName] ? '' : (calendarDefinition.endTime.fieldType === enumerators.FormatType.Time ? row[calendarDefinition.endTime.fieldName] : row[calendarDefinition.endTime.fieldName].toISOString());
                   
                    const startDate = eventStartDate.split('T');
                    if (!eventStartTime) {
                        const changedEventStart = changeTimeZoneFromUTC(serverTimeZone, clientTimeZone,
                            (startDate[0] + 'T00:00:00'));
                        row[startDateFieldName] = changedEventStart;
                        row["tz" + calendarDefinition.startDate.fieldName] = changedEventStart;
                    }
                    else {
                        const arrayStartTime = eventStartTime.split('T');
                        const startTime = arrayStartTime.length === 1 ? arrayStartTime[0] : arrayStartTime[1];
                        const changedEventStart = changeTimeZoneFromUTC(serverTimeZone, clientTimeZone,
                            (startDate[0] + 'T' + startTime));
                        row[startDateFieldName] = changedEventStart;
                        row["tz" + calendarDefinition.startDate.fieldName] =changedEventStart;
                      row["tz"+calendarDefinition.startTime.fieldName] = moment(changedEventStart).format(timeFormat);
                    }

                    if (!eventEndDate) {
                        row[endDateFieldName] = ''
                    }
                    else {
                        const endDate = eventEndDate.split('T');
                        if (!eventEndTime) {
                            const changedEventEnd =
                                changeTimeZoneFromUTC(serverTimeZone, clientTimeZone, (endDate[0] + 'T00:00:00'));
                            row[endDateFieldName] = changedEventEnd;
                            row["tz" + calendarDefinition.endDate.fieldName] = changedEventEnd;
                            row["tz" +calendarDefinition.endTime.fieldName] = moment(changedEventEnd).format(timeFormat);
                        }
                        else {
                            const arrayEndTime = eventEndTime.split('T');
                            const endTime = arrayEndTime.length === 1 ? arrayEndTime[0] : arrayEndTime[1];
                            const changedEventEnd =
                                changeTimeZoneFromUTC(serverTimeZone, clientTimeZone, (endDate[0] + 'T' + endTime));
                             row[endDateFieldName] = changedEventEnd;
                          
                            row["tz" + calendarDefinition.endDate.fieldName] = changedEventEnd;
                            row["tz" +calendarDefinition.endTime.fieldName] = moment(changedEventEnd).format(timeFormat);
                        }
                    }


                    row["Subject"] = this.getTitleAndFieldByIndex(0, row);
                    return (
                        row
                    );
                });
            }
        }

        
        this.setState({ eventLoading: false, eventData: eventDataSet, startDateFieldName, endDateFieldName});
    }    
    isMobileDevice = () => {
        return deviceType !== Enums.DeviceType.Desktop ? true : false;
    }
    getHeaderDetails(data) {
        if (this.props.definition.calendar.eventFieldSettings === enumerators.CalendarEventFieldSettings.HasStartDate
            || this.props.definition.calendar.eventFieldSettings === enumerators.CalendarEventFieldSettings.HasStartDateTime) {
            const timeFormat = this.instance.formatDate(data[this.state.startDateFieldName], { skeleton: 'hm' });

            return `${this.instance.formatDate(data[this.state.startDateFieldName], { type: 'date', skeleton: 'full' })} (${timeFormat} - ${timeFormat})`;
        }
        return `${this.instance.formatDate(data[this.state.startDateFieldName], { type: 'date', skeleton: 'full' })} (${this.instance.formatDate(data[this.state.startDateFieldName], { skeleton: 'hm' })} - ${this.instance.formatDate(data[this.state.endDateFieldName], { skeleton: 'hm' })})`;
             
             
    }
    closeQuickInfoPopup = () => {
        this.scheduleObj.closeQuickInfoPopup();
    }
    footerTemplate(props) {
        const data = this.state.eventData.find(({ VgoRowId }) => VgoRowId === props.VgoRowId);
        var dataCopy = { ...data };
        const calendarDefinition = this.props.definition.calendar;
        dataCopy[calendarDefinition.startTime.fieldName] = dataCopy["tz" + calendarDefinition.startTime.fieldName]
        dataCopy[calendarDefinition.endTime.fieldName] = dataCopy["tz" + calendarDefinition.endTime.fieldName]
        dataCopy[calendarDefinition.startDate.fieldName] = dataCopy["tz" + calendarDefinition.startDate.fieldName]
        dataCopy[calendarDefinition.endDate.fieldName] = dataCopy["tz" + calendarDefinition.endDate.fieldName]
        return (<SchedulerFooter loadingAction={this.props.loadingAction} handleAlertHandlerMessage={this.props.handleAlertHandlerMessage} setOpenMore={this.setOpenMore} schedulerdata={dataCopy} pushActionLinkToHistory={this.props.pushActionLinkToHistory} definition={this.props.definition} rowId={props.VgoRowId} closeQuickInfoPopup={this.closeQuickInfoPopup}></SchedulerFooter>);
    }
    headerTemplate(props) {
        const { classes } = this.props;
        return (<div className={classes.schedulePopUp}>
            <div className="quick-info-header">
            <div className="quick-info-header-content" >
                <div className="quick-info-title">Schedule details</div>
                <div className="duration-text">{this.getHeaderDetails(props)}</div>
            </div>
        </div></div>);
    }
    contentTemplate(props) {
        const { classes } = this.props;
        return (
            <div className={classes.schedulePopUp}>
            <div className="quick-info-content">
            <div className="event-content">
                <div className="meeting-subject-wrap">
                    <label>{props.Subject}</label>
                </div>
                {this.props.titleFieldCount === 2 && <div className="meeting-subject-wrap">
                    <label>{this.getTitleAndFieldByIndex(1, props)}</label>
                </div>}
                {this.props.titleFieldCount === 3 &&
                    <div>
                        <div className="meeting-subject-wrap">
                            <label>{this.getTitleAndFieldByIndex(1, props)}</label>
                        </div>
                        <div className="meeting-subject-wrap">
                            <label>{this.getTitleAndFieldByIndex(2, props)}</label>
                        </div>
                    </div>
                }
            </div>
            </div>
            </div>
            );
    } 
    onActionBegin(args) {
       
        if (args.requestType === 'toolbarItemRendering') {
            
            const sccheduleDataPickerId = `#schdatepicker`;
            let customDateSelect = { template: document.querySelector(sccheduleDataPickerId) };
            args.items[2] = customDateSelect;
            if (this.isMobileDevice()) {
                args.items[4] = null;
            }
             
        }
    }
    onCreated(args) {
        const actionBarHeight = this.scheduleObj.reactElement.parentElement.parentElement.parentElement.parentElement.parentElement.firstChild.clientHeight
        let height = 80;
       if (!this.isMobileDevice()) {
            if (this.props.panelSizeY === 2) {
                height = 90;
            }
        } else {
            if (this.props.panelSizeY === 2) {
                height = 88;
           }
           if (this.props.panelSizeX === 1 && this.props.panelSizeY === 1) {
               height = 78;
           }
          
        }
        this.scheduleObj.reactElement.parentElement.style.height = `${height}%`;
    }
    onEventRendered(args) {
        
        if (this.scheduleObj.currentView === "WorkWeek" || this.scheduleObj.currentView === "Week" || this.scheduleObj.currentView === "Day") {
            if (this.props.definition.calendar.eventFieldSettings === enumerators.CalendarEventFieldSettings.HasStartDate
                || this.props.definition.calendar.eventFieldSettings === enumerators.CalendarEventFieldSettings.HasStartDateTime) {
                if (args.element.childElementCount === 1 && args.element.childNodes[0].childElementCount === 3) {
                    const label = args.element.childNodes[0].childNodes[1].innerText.split('-');
                    if (label.length > 0) {
                        args.element.childNodes[0].childNodes[1].innerText = `${label[0]} - ${label[0]}`
                    }
                    
                }
                
            }
            
        }
        
    }
    
    OnpopupClose(args) {
        
        if (args.type == 'QuickInfo' && OpenMore == true) {
            if (this.setOpenMode) {
                this.setOpenMore(false);
            }
            args.cancel = true;
    }
};
    render() {


        const calendarDefinition = this.props.definition.calendar;
        const createdEvent = this.props.isFromDashboard ? { created: this.onCreated.bind(this), height: '100%' } : { height: this.isMobileDevice() ?'600px':'830px' }
        const boxPadding = this.props.isFromDashboard ? { pl: 1, pr: 1 } : { pl: 1, pr: this.isMobileDevice() ? 0 : 0 }
        const enableAdative = this.isMobileDevice() ? { enableAdaptiveUI: true } : { }
       
        return (<Box height="100%" {...boxPadding} >

            <div >
               {/* {(this.state.eventLoading || this.props.loadingEventData) && <Loading />}*/}
                <ScheduleComponent
                   
                    id={`scheduler_${this.props.elementId}`}
                    readonly={true}
                   //timezone={this.props.timezone}
                    ref={schedule => this.scheduleObj = schedule}
                    delayUpdate="true"
                   //commented to avoid custom tool bar actionBegin={this.onActionBegin.bind(this)}
                    selectedDate={new Date()}
                    eventRendered={this.onEventRendered.bind(this)}
                    popupClose={this.OnpopupClose}
                   // {...enableAdative}
                   // enableAdaptiveUI="true"
                    {...createdEvent}
                    quickInfoTemplates={{
                        header: this.headerTemplate.bind(this),
                        content: this.contentTemplate.bind(this),
                        footer: this.footerTemplate.bind(this)
                    }}
                    eventSettings=
                    {{
                        dataSource: this.state.eventData,
                        fields: {
                            id: 'VgoRowId',
                            startTime: { name: "VGO_StartTime" + calendarDefinition.startDate.fieldName },
                            endTime: { name: "VGO_EndTime" + calendarDefinition.endDate.fieldName },
                        }
                    }}>
                    <ViewsDirective>
                        <ViewDirective option='Day' />
                        <ViewDirective option='Week' />
                        <ViewDirective option='WorkWeek' />
                        <ViewDirective option='Month' isSelected={true} />
                        <ViewDirective option='Year' />
                        <ViewDirective option='Agenda' />

                    </ViewsDirective>
                    <Inject services={[Day, Week, WorkWeek, Month, Year, Agenda]} />
                </ScheduleComponent>
                {/*<div>*/}
                {/*    {<SchedulerCustomToolBar elementId={this.props.elementId} schedulerId={`scheduler_${this.props.elementId}`} />}*/}
                {/*</div>*/}

            </div>
        </Box>
        );
    }
}
export default withStyles(scheduleTheme)(Schedule) ;