import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { homeTheme } from 'client/components/Feed/theme/homeTheme';
import { Box, Typography } from '@mui/material'
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
class customLoading extends React.Component  {

    render() {
        const position = (this.props.isDashboard) ? "absolute" : "fixed";
        return (
            <Backdrop className={this.props.classes.BackdropLoadingStyle} open={this.props.loadingState.loading} sx={{ position: position, }}>
            <CircularProgress color="inherit" />
            <Box
                sx={{
                    top: 0,
                    left: 130,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="caption" component="div" color="inherit"> Loading </Typography>
            </Box>
        </Backdrop>
        )
    }

}

const mapStateToProps = (state) => {
    return {

        loadingState: state.loading,
    }
}
export default connect(state => mapStateToProps, null)(withStyles(homeTheme)(customLoading));

