import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import * as Objects from './Objects';
import * as Banner from './Banner';
import * as News from './News';
import * as Login from './Login';
import * as Layout from './Layout';
import * as CustomWidget from './CustomWidget';
import * as ContentList from './ContentList';
import * as ContentDetail from './ContentDetail';
import * as UserProfile from './UserProfile';
import * as Error from './Error';
import * as AlertHandler from './AlertHandler';
import * as AuthStatus from './AuthStatus';
import * as LinkRoute from './LinkRoute';
import * as DataView from './DataView';
import * as Loading from './Loading';

export default function configureStore(history, initialState) {
  const reducers = {
      objects: Objects.reducer,
      banners: Banner.reducer,
      news: News.reducer,
      customWidget: CustomWidget.reducer,
      Login:Login.reducer,
      layout: Layout.reducer,
      contents: ContentList.reducer,
      contentDetail: ContentDetail.reducer,
      UserProfile: UserProfile.reducer,
      error: Error.reducer,
      alertHandler: AlertHandler.reducer,
      authStatus: AuthStatus.reducer,
      dataView: DataView.reducer,
      linkRoute: LinkRoute.reducer,
      loading: Loading.reducer
     
  };

  const middleware = [
    thunk,
    routerMiddleware(history)
  ];

  // In development, use the browser's Redux dev tools extension if installed
  const enhancers = [];
  const isDevelopment = process.env.NODE_ENV === 'development';
  if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
    enhancers.push(window.devToolsExtension());
  }

  const rootReducer = combineReducers({
    ...reducers,
    routing: routerReducer
  });

  return createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers)
  );
}
